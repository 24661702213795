import { isProduction } from '..';
import type { AppConfig } from '../types';

export default {
  zone: 'asia-3',
  defaultLocale: 'th-TH',
  availableLocales: ['en-TH', 'th-TH'],
  timedBanners: {
    cookieConsent: {
      displayDates: {
        startDate: '2024-01-19T00:00:00.000Z',
      },
    },
  },
  zoneFeatures: {
    NORTHSTAR_SEO_TAGS: true,
    NUDGE_ENABLED: false,
    HOMEPAGE_BANNER_TYPE: 'GENERIC_ASIA_BANNER',
    SEARCH_PAGE_SIZE: 30,
    PDPA_CONSENT_REQUIRED: true,
    SHOW_FLOATING_SAVE_SEARCH: false,
    AUTO_SELECT_SPLIT_VIEW_FIRST_JOB: false,
    SHOW_MARKETING_AD_SPLIT_VIEW: false,
    LMIS_ENABLED: true,
    BEHAVIOURAL_CUES_ENABLED: false,
    BEHAVIOURAL_CUES_FILTERS_ENABLED: false,
    LOGGED_OUT_RECS: false,
    REMOTE_SEARCH_FILTER: false,
    REMOTE_SEARCH_FILTER_NEW_LABEL: true,
    DYNAMIC_PILLS: false,
    SECONDARY_FILTERS: false,
    ENABLE_V5_JOB_SEARCH: false,
    KEYWORD_AUTOSUGGEST_V2: true,
    NEW_JOB_CARD_DENSITY: false,
    ENABLE_VERIFIED_HIRER_BADGE: true,
    SERP_JOBCARD_INFO_DENSITY_1: false,
    MATCHED_QUALITIES: false,
  },
  BRANCH_IO_KEY: isProduction
    ? 'key_live_dgOh8Yali0X6D5LPpX679mjkuFnVPBnB'
    : 'key_test_baKe5Wbdl33XD3LQi6260idbvzfPPAfV',
} as AppConfig;
