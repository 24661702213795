import { useTranslations } from '@vocab/react';
import size from 'lodash/size';

import { useSearchParams } from '../search-params';
import { useCountryTitle } from '../titleOverride/replaceableKeyValues/country';
import { useMonthYear } from '../titleOverride/replaceableKeyValues/monthYear';
import { useSearchResultsStateKeyValues } from '../titleOverride/replaceableKeyValues/resultStateKeyValues';
import { useTitleOverride } from '../titleOverride/titleOverride';
import type {
  ReplaceableKeyValues,
  TitleType,
  UnifiedTitleTypeValues,
} from '../titleOverride/type';
import { appendSpacePrefix, generateTranslationTypeKey } from '../utils';

import translations from './.vocab';

export const useSearchResultsHeadTitle = ({
  page,
  fallBackTitle,
  isCompanySearch = false,
}: {
  page: string;
  fallBackTitle: string;
  isCompanySearch?: boolean;
}): string => {
  const { t } = useTranslations(translations);
  const countryTitle = useCountryTitle();
  const searchParams = useSearchParams();
  const { month, year } = useMonthYear();
  const {
    brand,
    keywords,
    locationDescription: locationDescriptionFromState,
    workTypeDescription,
    workArrangementDescription,
    classDescription,
    jobCount,
    shortLocationName,
  } = useSearchResultsStateKeyValues({ mode: 'title' });

  const locationDescription = locationDescriptionFromState || countryTitle; // Fallback to country title if no location description
  const key: string = generateTranslationTypeKey({
    isCompanySearch,
    searchParams,
    classDescription,
    fallbackKey: 'noparams-title',
  });

  const pageNumberText: string = appendSpacePrefix(
    parseInt(page, 10) > 1 ? t('page-number-text', { page }) : '',
  );

  const pageTitle: string = useTitle(
    {
      brand,
      country: countryTitle,
      classDescription,
      fallBackTitle,
      keywords,
      locationDescription,
      month,
      workTypeDescription,
      workArrangementDescription,
      year,
      pageNumberText,
      subclassOnlyDescription: '',
      jobCount: jobCount ? jobCount.toString() : '',
      shortLocationName: shortLocationName || locationDescription, // Fallback to locationDescription if shortLocationName is not available
    },
    key as TitleType,
  );

  return pageTitle;
};

const useTitle = (
  replaceableKeyValues: ReplaceableKeyValues,
  titleType: TitleType,
): string => {
  const { t } = useTranslations(translations);

  const {
    brand,
    country,
    classDescription,
    fallBackTitle,
    keywords,
    locationDescription,
    month,
    workTypeDescription,
    workArrangementDescription,
    year,
    pageNumberText,
  } = replaceableKeyValues;

  const formattedFallBackTitle: string = size(fallBackTitle)
    ? `${fallBackTitle}${appendSpacePrefix(pageNumberText)} | ${brand}`
    : brand;

  const titleTypes: UnifiedTitleTypeValues = {
    company: t('company-title', {
      keywords,
      country,
      brand,
      month,
      year,
      pageNumberText,
    }),
    'company-location': t('company-location-title', {
      keywords,
      locationDescription,
      pageNumberText,
      brand,
      month,
      year,
    }),
    'company-worktype': t('company-worktype-title', {
      keywords,
      workTypeDescription,
      pageNumberText,
      brand,
      month,
      year,
    }),
    'company-location-worktype': t('company-location-worktype-title', {
      keywords,
      workTypeDescription,
      locationDescription,
      pageNumberText,
      brand,
      month,
      year,
    }),
    classification: t('classification-title', {
      classDescription,
      country,
      pageNumberText,
      month,
      year,
      brand,
    }),
    'classification-company': t('classification-company-title', {
      classDescription,
      keywords,
      pageNumberText,
      brand,
      month,
      year,
    }),
    'classification-company-worktype': t(
      'classification-company-worktype-title',
      {
        keywords,
        workTypeDescription,
        classDescription,
        pageNumberText,
        brand,
        month,
        year,
      },
    ),
    'classification-keywords': t('classification-keywords-title', {
      keywords,
      classDescription,
      country,
      pageNumberText,
      brand,
      month,
      year,
    }),
    'classification-keywords-location': t(
      'classification-keywords-location-title',
      {
        keywords,
        classDescription,
        locationDescription,
        pageNumberText,
        brand,
        month,
        year,
      },
    ),
    'classification-keywords-location-worktype': t(
      'classification-keywords-location-worktype-title',
      {
        keywords,
        workTypeDescription,
        classDescription,
        locationDescription,
        pageNumberText,
        brand,
        month,
        year,
      },
    ),
    'classification-location': t('classification-location-title', {
      classDescription,
      locationDescription,
      pageNumberText,
      brand,
      month,
      year,
    }),
    'classification-location-worktype': t(
      'classification-location-worktype-title',
      {
        workTypeDescription,
        classDescription,
        locationDescription,
        pageNumberText,
        brand,
        month,
        year,
      },
    ),
    'classification-worktype': t('classification-worktype-title', {
      workTypeDescription,
      classDescription,
      country,
      pageNumberText,
      brand,
      month,
      year,
    }),
    keywords: t('keywords-title', {
      keywords,
      country,
      pageNumberText,
      brand,
      month,
      year,
    }),
    'keywords-location': t('keywords-location-title', {
      keywords,
      locationDescription,
      pageNumberText,
      month,
      year,
      brand,
    }),
    'keywords-location-worktype': t('keywords-location-worktype-title', {
      keywords,
      workTypeDescription,
      locationDescription,
      pageNumberText,
      brand,
      month,
      year,
    }),
    'keywords-worktype': t('keywords-worktype-title', {
      keywords,
      workTypeDescription,
      country,
      pageNumberText,
      month,
      year,
      brand,
    }),
    location: t('location-title', {
      locationDescription,
      pageNumberText,
      brand,
      month,
      year,
    }),
    'location-worktype': t('location-worktype-title', {
      workTypeDescription,
      locationDescription,
      pageNumberText,
      month,
      year,
      brand,
    }),
    worktype: t('worktype-title', {
      workTypeDescription,
      country,
      pageNumberText,
      brand,
      month,
      year,
    }),
    workarrangement: t('workarrangement-title', {
      workArrangementDescription,
      country,
      pageNumberText,
      brand,
      month,
      year,
    }),
    'classification-workarrangement': t(
      'classification-workarrangement-title',
      {
        classDescription,
        workArrangementDescription,
        country,
        pageNumberText,
        brand,
        month,
        year,
      },
    ),
    'classification-company-workarrangement': t(
      'classification-company-workarrangement-title',
      {
        classDescription,
        keywords,
        workArrangementDescription,
        country,
        pageNumberText,
        brand,
        month,
        year,
      },
    ),
    'classification-keywords-workarrangement': t(
      'classification-keywords-workarrangement-title',
      {
        classDescription,
        keywords,
        workArrangementDescription,
        country,
        pageNumberText,
        brand,
        month,
        year,
      },
    ),
    'classification-keywords-location-workarrangement': t(
      'classification-keywords-location-workarrangement-title',
      {
        classDescription,
        keywords,
        locationDescription,
        workArrangementDescription,
        pageNumberText,
        brand,
        month,
        year,
      },
    ),
    'classification-keywords-workarrangement-worktype': t(
      'classification-keywords-workarrangement-worktype-title',
      {
        classDescription,
        keywords,
        workArrangementDescription,
        workTypeDescription,
        country,
        pageNumberText,
        brand,
        month,
        year,
      },
    ),
    'classification-keywords-location-workarrangement-worktype': t(
      'classification-keywords-location-workarrangement-worktype-title',
      {
        classDescription,
        keywords,
        locationDescription,
        workArrangementDescription,
        workTypeDescription,
        pageNumberText,
        brand,
        month,
        year,
      },
    ),
    'classification-location-workarrangement': t(
      'classification-location-workarrangement-title',
      {
        classDescription,
        locationDescription,
        workArrangementDescription,
        pageNumberText,
        brand,
        month,
        year,
      },
    ),
    'classification-company-location-workarrangement': t(
      'classification-company-location-workarrangement-title',
      {
        classDescription,
        locationDescription,
        workArrangementDescription,
        keywords,
        pageNumberText,
        brand,
        month,
        year,
      },
    ),
    'classification-workarrangement-worktype': t(
      'classification-workarrangement-worktype-title',
      {
        classDescription,
        workArrangementDescription,
        workTypeDescription,
        country,
        pageNumberText,
        brand,
        month,
        year,
      },
    ),
    'classification-company-workarrangement-worktype': t(
      'classification-company-workarrangement-worktype-title',
      {
        classDescription,
        keywords,
        workArrangementDescription,
        workTypeDescription,
        country,
        pageNumberText,
        brand,
        month,
        year,
      },
    ),
    'classification-location-workarrangement-worktype': t(
      'classification-location-workarrangement-worktype-title',
      {
        classDescription,
        locationDescription,
        workArrangementDescription,
        workTypeDescription,
        pageNumberText,
        brand,
        month,
        year,
      },
    ),
    'classification-company-location-workarrangement-worktype': t(
      'classification-company-location-workarrangement-worktype-title',
      {
        classDescription,
        locationDescription,
        workArrangementDescription,
        keywords,
        workTypeDescription,
        pageNumberText,
        brand,
        month,
        year,
      },
    ),
    'classification-subclassification-workarrangement': t(
      'classification-workarrangement-title',
      {
        classDescription,
        workArrangementDescription,
        country,
        pageNumberText,
        brand,
        month,
        year,
      },
    ),
    'classification-company-subclassification-workarrangement': t(
      'classification-company-workarrangement-title',
      {
        classDescription,
        keywords,
        workArrangementDescription,
        country,
        pageNumberText,
        brand,
        month,
        year,
      },
    ),
    'classification-keywords-subclassification-workarrangement': t(
      'classification-keywords-workarrangement-title',
      {
        classDescription,
        keywords,
        workArrangementDescription,
        country,
        pageNumberText,
        brand,
        month,
        year,
      },
    ),
    'classification-keywords-location-subclassification-workarrangement': t(
      'classification-keywords-location-workarrangement-title',
      {
        classDescription,
        keywords,
        locationDescription,
        workArrangementDescription,
        pageNumberText,
        brand,
        month,
        year,
      },
    ),
    'classification-keywords-subclassification-workarrangement-worktype': t(
      'classification-keywords-workarrangement-worktype-title',
      {
        classDescription,
        keywords,
        workArrangementDescription,
        workTypeDescription,
        country,
        pageNumberText,
        brand,
        month,
        year,
      },
    ),
    'classification-keywords-location-subclassification-workarrangement-worktype':
      t('classification-keywords-location-workarrangement-worktype-title', {
        classDescription,
        keywords,
        locationDescription,
        workArrangementDescription,
        workTypeDescription,
        pageNumberText,
        brand,
        month,
        year,
      }),
    'classification-location-subclassification-workarrangement': t(
      'classification-location-workarrangement-title',
      {
        classDescription,
        locationDescription,
        workArrangementDescription,
        pageNumberText,
        brand,
        month,
        year,
      },
    ),
    'classification-company-location-subclassification-workarrangement': t(
      'classification-company-location-workarrangement-title',
      {
        classDescription,
        locationDescription,
        workArrangementDescription,
        keywords,
        pageNumberText,
        brand,
        month,
        year,
      },
    ),
    'classification-subclassification-workarrangement-worktype': t(
      'classification-workarrangement-worktype-title',
      {
        classDescription,
        workArrangementDescription,
        workTypeDescription,
        country,
        pageNumberText,
        brand,
        month,
        year,
      },
    ),
    'classification-company-subclassification-workarrangement-worktype': t(
      'classification-company-workarrangement-worktype-title',
      {
        classDescription,
        keywords,
        workArrangementDescription,
        workTypeDescription,
        country,
        pageNumberText,
        brand,
        month,
        year,
      },
    ),
    'classification-location-subclassification-workarrangement-worktype': t(
      'classification-location-workarrangement-worktype-title',
      {
        classDescription,
        locationDescription,
        workArrangementDescription,
        workTypeDescription,
        pageNumberText,
        brand,
        month,
        year,
      },
    ),
    'classification-company-location-subclassification-workarrangement-worktype':
      t('classification-company-location-workarrangement-worktype-title', {
        classDescription,
        locationDescription,
        workArrangementDescription,
        keywords,
        workTypeDescription,
        pageNumberText,
        brand,
        month,
        year,
      }),
    'company-workarrangement': t('company-workarrangement-title', {
      keywords,
      workArrangementDescription,
      country,
      pageNumberText,
      brand,
      month,
      year,
    }),
    'keywords-workarrangement': t('keywords-workarrangement-title', {
      keywords,
      workArrangementDescription,
      country,
      pageNumberText,
      brand,
      month,
      year,
    }),
    'keywords-location-workarrangement': t(
      'keywords-location-workarrangement-title',
      {
        keywords,
        locationDescription,
        workArrangementDescription,
        brand,
        month,
        year,
        pageNumberText,
      },
    ),
    'keywords-workarrangement-worktype': t(
      'keywords-workarrangement-worktype-title',
      {
        keywords,
        workArrangementDescription,
        workTypeDescription,
        country,
        brand,
        month,
        year,
        pageNumberText,
      },
    ),
    'keywords-location-workarrangement-worktype': t(
      'keywords-location-workarrangement-worktype-title',
      {
        keywords,
        locationDescription,
        workArrangementDescription,
        workTypeDescription,
        pageNumberText,
        brand,
        month,
        year,
      },
    ),
    'location-workarrangement': t('location-workarrangement-title', {
      locationDescription,
      workArrangementDescription,
      pageNumberText,
      brand,
      month,
      year,
    }),
    'company-location-workarrangement': t(
      'company-location-workarrangement-title',
      {
        locationDescription,
        keywords,
        workArrangementDescription,
        brand,
        month,
        year,
        pageNumberText,
      },
    ),
    'workarrangement-worktype': t('workarrangement-worktype-title', {
      workArrangementDescription,
      workTypeDescription,
      country,
      pageNumberText,
      brand,
      month,
      year,
    }),
    'company-workarrangement-worktype': t(
      'company-workarrangement-worktype-title',
      {
        keywords,
        workArrangementDescription,
        workTypeDescription,
        country,
        brand,
        month,
        year,
        pageNumberText,
      },
    ),
    'location-workarrangement-worktype': t(
      'location-workarrangement-worktype-title',
      {
        locationDescription,
        workArrangementDescription,
        workTypeDescription,
        brand,
        month,
        year,
        pageNumberText,
      },
    ),
    'company-location-workarrangement-worktype': t(
      'company-location-workarrangement-worktype-title',
      {
        locationDescription,
        keywords,
        workArrangementDescription,
        workTypeDescription,
        pageNumberText,
        brand,
        month,
        year,
      },
    ),
  };

  const noParamsTitle = t('noparams-title', {
    country,
    month,
    year,
    brand,
    pageNumberText,
  });

  return (
    useTitleOverride({
      replaceableKeyValues,
      titleType,
    }) ||
    titleTypes[titleType as keyof typeof titleTypes] ||
    noParamsTitle ||
    formattedFallBackTitle
  ); // return title from graph if lookup fails;
};
