import type { SearchParams } from '@seek/chalice-types';
import { parse } from 'query-string';

import { isGoogleUrl } from 'src/modules/external-sources';

export const isPillsEnabled = ({
  isDynamicPillsEnabled,
  searchParams,
}: {
  isDynamicPillsEnabled?: boolean;
  searchParams: SearchParams;
}): boolean => {
  const isReferrerFromGoogle =
    typeof document !== 'undefined' && isGoogleUrl(document.referrer);

  // Todo: Revisit searches that contain pos query param such
  // those originated from recent searches.
  const isQueryContainPos =
    typeof window !== 'undefined' ? parse(window.location.search).pos : '';

  return (
    Boolean(isDynamicPillsEnabled) &&
    !isReferrerFromGoogle &&
    !searchParams?.savedsearchid &&
    !isQueryContainPos
  );
};
