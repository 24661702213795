import { isProduction } from '..';
import type { AppConfig } from '../types';

export default {
  zone: 'asia-6',
  defaultLocale: 'en-PH',
  availableLocales: ['en-PH'],
  timedBanners: {
    launchBanner: {
      displayDates: {
        startDate: '2024-11-01T11:00:00.000Z', // 01 November 2024, 19:00:00 Local Time
        endDate: '2024-11-15T16:00:00.000Z', // 16 November 2024, 00:00:00 Local Time
      },
      bannerUrl: {
        en: 'https://help.ph.jobstreet.com/s/article/Why-has-the-website-address-changed-PH',
      },
      oldUrl: 'jobstreet.com.ph',
      newUrl: 'ph.jobstreet.com',
    },
  },
  zoneFeatures: {
    NORTHSTAR_SEO_TAGS: true,
    NUDGE_ENABLED: false,
    HOMEPAGE_BANNER_TYPE: 'GENERIC_ASIA_BANNER',
    SEARCH_PAGE_SIZE: 30,
    SHOW_FLOATING_SAVE_SEARCH: false,
    AUTO_SELECT_SPLIT_VIEW_FIRST_JOB: false,
    SHOW_MARKETING_AD_SPLIT_VIEW: false,
    LMIS_ENABLED: true,
    BEHAVIOURAL_CUES_ENABLED: false,
    BEHAVIOURAL_CUES_FILTERS_ENABLED: false,
    LOGGED_OUT_RECS: false,
    REMOTE_SEARCH_FILTER: false,
    REMOTE_SEARCH_FILTER_NEW_LABEL: true,
    DYNAMIC_PILLS: false,
    SECONDARY_FILTERS: false,
    ENABLE_V5_JOB_SEARCH: false,
    KEYWORD_AUTOSUGGEST_V2: true,
    NEW_JOB_CARD_DENSITY: false,
    ENABLE_VERIFIED_HIRER_BADGE: true,
    SERP_JOBCARD_INFO_DENSITY_1: false,
    MATCHED_QUALITIES: false,
  },
  BRANCH_IO_KEY: isProduction
    ? 'key_live_keVW4JKlS0jBJLPfSRNUJnhhtElmK541'
    : 'key_test_igQ77GMmQ1itGVIiMGXzojmavAcg9pio',
} as AppConfig;
