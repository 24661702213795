import querystring from 'query-string';

const defaultCandidateResolver = {
  __typename: 'Candidate',
  personalDetails: {
    firstName: 'Joe',
  },
  emailAddress: 'joe@gmail.com',
  id: 39675318,
  trackingId: '2cef3f488f07f1e946cd6a6bdcc62bf9',
  userCookie: {
    key: 'last-known-sol-user-id',
    value: '3364edb5-9fa8-40bc-9068-2cb3c032529c',
  },
};

const defaultMockResolvers = {
  Candidate: () => defaultCandidateResolver,
};

export default () => {
  const { testData = '' } =
    typeof window !== 'undefined'
      ? querystring.parseUrl(window.location.href).query
      : {};

  if (testData === 'apacSavedSearchesError') {
    return {
      ...defaultMockResolvers,
      Candidate: () => ({
        ...defaultCandidateResolver,
        apacSavedSearches: new Error('ERROR_WITH_APAC_SAVED_SEARCHES_RESOLVER'),
      }),
    };
  }

  if (testData === 'newCandidateNoProfileSetup') {
    return {
      ...defaultMockResolvers,
      Candidate: () => ({
        ...defaultCandidateResolver,
        apacSavedSearches: [],
      }),
    };
  }

  return defaultMockResolvers;
};
