import type { SearchParams } from '@seek/chalice-types';
import { useTranslations } from '@vocab/react';
import startCase from 'lodash/startCase';
import type { Store } from 'redux';

import { useAppConfig } from 'src/config/appConfig';
import type { ZoneConfig } from 'src/config/types';
import { brandToCaseMap } from 'src/config/utils/constants';
import anz1Config from 'src/config/zone-configs/anz-1';
import anz2Config from 'src/config/zone-configs/anz-2';
import asia1Config from 'src/config/zone-configs/asia-1';
import asia3Config from 'src/config/zone-configs/asia-3';
import asia4Config from 'src/config/zone-configs/asia-4';
import asia5Config from 'src/config/zone-configs/asia-5';
import asia6Config from 'src/config/zone-configs/asia-6';
import asia7Config from 'src/config/zone-configs/asia-7';
import {
  getWorkArrangements,
  getWorkTypes,
} from 'src/modules/refine-job-search';
import { useSelector } from 'src/store/react';
import { selectTitleTagShortLocationName } from 'src/store/results/selectors';
import { selectResultsLocation, selectResultsCount } from 'src/store/selectors';
import type { ChaliceStore } from 'src/store/types';

import { useSearchParams } from '../../search-params';
import { toProperCase } from '../../utils';

import translations from './.vocab';
import { getClassificationDescription } from './classifications';
import type { ReplaceableKeyValuesMode, VocabLocale } from './types';

const zoneConfig: Record<string, ZoneConfig> = {
  'anz-1': anz1Config,
  'anz-2': anz2Config,
  'asia-1': asia1Config,
  'asia-3': asia3Config,
  'asia-4': asia4Config,
  'asia-5': asia5Config,
  'asia-6': asia6Config,
  'asia-7': asia7Config,
};

const shouldUseCommaFormattedClassification = (searchParams: SearchParams) => {
  const numSearchParams = Object.keys(searchParams).length;
  const numSubclassifications =
    searchParams?.subclassification?.split(',').length || 0;
  const numParentClassifications =
    searchParams?.classification?.split(',').length || 0;
  const isLocationOrWorktypeSelected =
    Boolean(searchParams?.worktype) || Boolean(searchParams?.where);
  const isWorkarrangementSelected = Boolean(searchParams?.workarrangement);

  const isKeywordsSelected = Boolean(searchParams?.keywords);
  const isCommaFormattedClassWithLocationOrWorktypeSelected =
    numSearchParams === 3 &&
    numSubclassifications === 1 &&
    numParentClassifications === 1 &&
    isLocationOrWorktypeSelected;

  const isCommaFormattedClassWithWorkarrangementSelected =
    numSearchParams === 4 &&
    numSubclassifications === 1 &&
    numParentClassifications === 1 &&
    isWorkarrangementSelected &&
    !isKeywordsSelected;

  return (
    isCommaFormattedClassWithLocationOrWorktypeSelected ||
    isCommaFormattedClassWithWorkarrangementSelected
  );
};

export const useSearchResultsStateKeyValues = ({
  mode,
}: {
  mode: ReplaceableKeyValuesMode;
}) => {
  const { t } = useTranslations(translations);
  const searchParams = useSearchParams();
  const resultsLocation = useSelector(selectResultsLocation);
  const { brand, zone, locale, language } = useAppConfig();
  const { defaultLocale } = useAppConfig();
  const formattedBrand = brandToCaseMap[brand];
  const keywords: string | null | undefined = toProperCase(
    searchParams?.keywords,
  );
  const classification: string | null | undefined =
    searchParams?.classification;
  const subclassification: string | null | undefined =
    searchParams?.subclassification;
  const workArrangement: string | null | undefined =
    searchParams?.workarrangement;
  const workType: string | null | undefined = searchParams?.worktype;
  const locationDescription: string = resultsLocation?.description || '';

  // fallback shortLocationName to locationDescription
  // when user performs a new search in browser.
  const shortLocationName: string | undefined =
    useSelector(selectTitleTagShortLocationName) || locationDescription;

  const workTypeList = getWorkTypes(zone, language);

  const workTypeDescription = startCase(
    workTypeList?.find(({ value }) => value === workType)?.label,
  );

  const workArrangementList = getWorkArrangements(locale);
  const workArrangementDescription = startCase(
    workArrangementList?.find(({ id }) => id === workArrangement)?.label,
  );

  const classRemainderFn =
    mode === 'title'
      ? (classificationCount: number) =>
          ` ${t('additional-classifications-title', {
            classificationCount,
          })}` // Be wary of the space prefix here
      : (classificationCount: number) =>
          ` ${t('additional-classifications-description', {
            classificationCount,
          })}`; // Be wary of the space prefix here

  const commaFormattedClassification =
    mode === 'description' &&
    shouldUseCommaFormattedClassification(searchParams);

  const classDescription: string = getClassificationDescription({
    zone,
    language,
    defaultLocale,
    classification,
    subclassification,
    classRemainderFn,
    commaFormattedClassification,
  });
  const subclassOnlyDescription: string = getClassificationDescription({
    zone,
    language,
    defaultLocale,
    classification: undefined,
    subclassification,
    classRemainderFn,
    commaFormattedClassification,
  });
  const jobCount = useSelector(selectResultsCount) || 0;

  return {
    brand: formattedBrand,
    keywords,
    locationDescription,
    workArrangementDescription,
    workTypeDescription,
    classDescription,
    subclassOnlyDescription,
    jobCount,
    shortLocationName,
  };
};

export const getResultsStateKeyValues = async ({
  store,
  mode,
}: {
  store: Store<ChaliceStore>;
  mode: ReplaceableKeyValuesMode;
}) => {
  const state = store.getState();
  const { brand, zone, locale, language } = state.appConfig;
  const messages = await translations.getMessages(locale as VocabLocale);
  const defaultLocale = zoneConfig[zone].defaultLocale || 'en_AU';
  const formattedBrand = brandToCaseMap[brand];
  const resultsLocation = state.results.location;
  const searchParams = state.results.results?.searchParams || {};

  const keywords: string | null | undefined = toProperCase(
    searchParams?.keywords,
  );
  const classification: string | null | undefined =
    searchParams?.classification;
  const subclassification: string | null | undefined =
    searchParams?.subclassification;
  const workArrangement: string | null | undefined =
    searchParams?.workarrangement;
  const workType: string | null | undefined = searchParams?.worktype;
  const locationDescription: string = resultsLocation?.description || '';

  const shortLocationName: string | undefined =
    state.results.titleTagShortLocationName || '';

  const jobCount = state.results.totalCount || 0;

  const workTypeList = getWorkTypes(zone, language);

  const workTypeDescription = startCase(
    workTypeList?.find(({ value }) => value === workType)?.label,
  );

  const workArrangementList = getWorkArrangements(locale);
  const workArrangementDescription = startCase(
    workArrangementList?.find(({ id }) => id === workArrangement)?.label,
  );

  const classRemainderFn =
    mode === 'title'
      ? (classificationCount: number) =>
          ` ${messages['additional-classifications-title'].format({
            classificationCount,
          })}` // Be wary of the space prefix here
      : (classificationCount: number) =>
          ` ${messages['additional-classifications-description'].format({
            classificationCount,
          })}`; // Be wary of the space prefix here

  const commaFormattedClassification =
    mode === 'description' &&
    shouldUseCommaFormattedClassification(searchParams);

  const classDescription: string = getClassificationDescription({
    zone,
    language,
    defaultLocale,
    classification,
    subclassification,
    classRemainderFn,
    commaFormattedClassification,
  });
  const subclassOnlyDescription: string = getClassificationDescription({
    zone,
    language,
    defaultLocale,
    classification: undefined,
    subclassification,
    classRemainderFn,
    commaFormattedClassification,
  });

  return {
    brand: formattedBrand,
    keywords,
    locationDescription,
    workArrangementDescription,
    workTypeDescription,
    classDescription,
    subclassOnlyDescription,
    jobCount,
    shortLocationName,
  };
};
