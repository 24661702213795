import { KeywordAutoSuggest } from '@seek/discover-ui';
import { useTranslations } from '@vocab/react';
import { Box } from 'braid-design-system';
import { useCallback, useEffect, useState, type ComponentProps } from 'react';

import { useAppConfig } from 'src/config/appConfig';
import { useAnalyticsFacade } from 'src/modules/AnalyticsFacade';
import { useDispatch, useSelector } from 'src/store/react';
import { clearResults } from 'src/store/results';
import {
  updateKeywordsField as _updateKeywordsField,
  updateBaseKeywordParam,
} from 'src/store/search';
import {
  selectFeatureFlag,
  selectSearchQuery,
  selectKeywordField,
  selectSessionId,
} from 'src/store/selectors';

import { getRefineSearchQuery } from '../../utils/utils';

import translations from './.vocab';

export const KEYWORD_INPUT_FIELD_ID = 'keywords-input';

interface KeywordFieldProps {
  onSuggestionSelected: () => void;
  onInputBlur: () => void;
  onClear: () => void;
}
const KeywordField = ({
  onSuggestionSelected,
  onInputBlur,
  onClear,
}: KeywordFieldProps) => {
  const analyticsFacade = useAnalyticsFacade();
  const dispatch = useDispatch();

  const keywordsField = useSelector(selectKeywordField);
  const query = getRefineSearchQuery(useSelector(selectSearchQuery));
  const { country } = useAppConfig();
  const [keywordsFieldLocalState, setKeywordFieldLocalState] =
    useState(keywordsField);
  const sessionId = useSelector(selectSessionId);
  const isKeywordAutosuggestV2Enabled = useSelector(
    selectFeatureFlag('keywordAutosuggestV2'),
  );
  const showDynamicPills = useSelector(selectFeatureFlag('dynamicPills'));

  useEffect(() => {
    if (keywordsField !== keywordsFieldLocalState) {
      setKeywordFieldLocalState(keywordsField);
    }
  }, [keywordsField, keywordsFieldLocalState]);

  const updateKeywordsField = useCallback(
    (text: string) => {
      setKeywordFieldLocalState(text);
      dispatch(_updateKeywordsField(text));

      if (showDynamicPills) {
        dispatch(updateBaseKeywordParam(text));
      }
    },
    [dispatch, showDynamicPills],
  );

  const onKeywordsChange = useCallback(
    (
      ...args: Parameters<ComponentProps<typeof KeywordAutoSuggest>['onChange']>
    ) => {
      const [suggestion] = args;
      analyticsFacade.keywordChanged(
        suggestion.text,
        suggestion.isSuggestion,
        suggestion.previousText ?? '',
        suggestion.keywordListCount,
        suggestion.keywordRank,
        suggestion.suggestionMetadata,
      );
      updateKeywordsField(suggestion.text);

      if (suggestion.isSuggestion) {
        onSuggestionSelected();
      }
    },
    [analyticsFacade, onSuggestionSelected, updateKeywordsField],
  );

  const onKeywordsBlur = () => {
    const { keywords = '' } = query;

    if (keywordsField !== keywords) {
      updateKeywordsField(keywordsField);
      onInputBlur();
    }
  };

  const onKeywordsClear = () => {
    dispatch(clearResults());
    updateKeywordsField('');
    onClear();
  };
  const { t } = useTranslations(translations);

  return (
    <Box
      role="region"
      aria-label={t('Enter Keywords')}
      data-automation="searchKeywordsField"
    >
      <KeywordAutoSuggest
        keywordAutosuggestV2={isKeywordAutosuggestV2Enabled}
        visitorId={sessionId}
        keyword={{ text: keywordsFieldLocalState }}
        id={KEYWORD_INPUT_FIELD_ID}
        onChange={onKeywordsChange}
        onBlur={onKeywordsBlur}
        onClear={onKeywordsClear}
        country={country.toLowerCase()}
        label={t('What')}
        placeholder={t('Enter Keywords')}
        reserveMessageSpace={false}
        showMobileBackdrop={true}
        name="keywords"
      />
    </Box>
  );
};

export default KeywordField;
