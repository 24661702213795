
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJjb3VudHJ5LXRpdGxlIjoiQXVzdHJhbGlhIiwiamFuLXRpdGxlIjoiSmFuIiwiZmViLXRpdGxlIjoiRmViIiwibWFyLXRpdGxlIjoiTWFyIiwiYXByLXRpdGxlIjoiQXByIiwibWF5LXRpdGxlIjoiTWF5IiwianVuLXRpdGxlIjoiSnVuIiwianVsLXRpdGxlIjoiSnVsIiwiYXVnLXRpdGxlIjoiQXVnIiwic2VwLXRpdGxlIjoiU2VwIiwib2N0LXRpdGxlIjoiT2N0Iiwibm92LXRpdGxlIjoiTm92IiwiZGVjLXRpdGxlIjoiRGVjIiwiYWRkaXRpb25hbC1jbGFzc2lmaWNhdGlvbnMtdGl0bGUiOiImIHtjbGFzc2lmaWNhdGlvbkNvdW50LCBwbHVyYWwsIG9uZSB7MSBtb3JlIGNsYXNzaWZpY2F0aW9ufSBvdGhlciB7e2NsYXNzaWZpY2F0aW9uQ291bnR9IG1vcmUgY2xhc3NpZmljYXRpb25zfX0iLCJhZGRpdGlvbmFsLWNsYXNzaWZpY2F0aW9ucy1kZXNjcmlwdGlvbiI6IiYge2NsYXNzaWZpY2F0aW9uQ291bnQsIHBsdXJhbCwgb25lIHsxIG1vcmUgY2xhc3NpZmljYXRpb259IG90aGVyIHt7Y2xhc3NpZmljYXRpb25Db3VudH0gbW9yZSBjbGFzc2lmaWNhdGlvbnN9fSIsInBhZ2UtbnVtYmVyLXRleHQiOiIoUGFnZSB7cGFnZX0pIiwibm9wYXJhbXMtdGl0bGUiOiJKb2JzIGluIHtjb3VudHJ5fSAtIHttb250aH0ge3llYXJ9e3BhZ2VOdW1iZXJUZXh0fSB8IHticmFuZH0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJjb3VudHJ5LXRpdGxlIjoiQXVzdHJhbGlhIiwiamFuLXRpdGxlIjoiSmFuIiwiZmViLXRpdGxlIjoiRmViIiwibWFyLXRpdGxlIjoiTWFyIiwiYXByLXRpdGxlIjoiQXByIiwibWF5LXRpdGxlIjoiTWF5IiwianVuLXRpdGxlIjoiSnVuIiwianVsLXRpdGxlIjoiSnVsIiwiYXVnLXRpdGxlIjoiQXVnIiwic2VwLXRpdGxlIjoiU2VwIiwib2N0LXRpdGxlIjoiT2N0Iiwibm92LXRpdGxlIjoiTm92IiwiZGVjLXRpdGxlIjoiRGVjIiwiYWRkaXRpb25hbC1jbGFzc2lmaWNhdGlvbnMtdGl0bGUiOiImIHtjbGFzc2lmaWNhdGlvbkNvdW50LCBwbHVyYWwsIG9uZSB7MSBtb3JlIGNsYXNzaWZpY2F0aW9ufSBvdGhlciB7e2NsYXNzaWZpY2F0aW9uQ291bnR9IG1vcmUgY2xhc3NpZmljYXRpb25zfX0iLCJhZGRpdGlvbmFsLWNsYXNzaWZpY2F0aW9ucy1kZXNjcmlwdGlvbiI6IiYge2NsYXNzaWZpY2F0aW9uQ291bnQsIHBsdXJhbCwgb25lIHsxIG1vcmUgY2xhc3NpZmljYXRpb259IG90aGVyIHt7Y2xhc3NpZmljYXRpb25Db3VudH0gbW9yZSBjbGFzc2lmaWNhdGlvbnN9fSIsInBhZ2UtbnVtYmVyLXRleHQiOiIoUGFnZSB7cGFnZX0pIiwibm9wYXJhbXMtdGl0bGUiOiJKb2JzIGluIHtjb3VudHJ5fSAtIHttb250aH0ge3llYXJ9e3BhZ2VOdW1iZXJUZXh0fSB8IHticmFuZH0ifQ==!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJjb3VudHJ5LXRpdGxlIjoiQXVzdHJhbGlhIiwiamFuLXRpdGxlIjoiSmFuIiwiZmViLXRpdGxlIjoiRmViIiwibWFyLXRpdGxlIjoiTWFyIiwiYXByLXRpdGxlIjoiQXByIiwibWF5LXRpdGxlIjoiTWF5IiwianVuLXRpdGxlIjoiSnVuIiwianVsLXRpdGxlIjoiSnVsIiwiYXVnLXRpdGxlIjoiQXVnIiwic2VwLXRpdGxlIjoiU2VwIiwib2N0LXRpdGxlIjoiT2N0Iiwibm92LXRpdGxlIjoiTm92IiwiZGVjLXRpdGxlIjoiRGVjIiwiYWRkaXRpb25hbC1jbGFzc2lmaWNhdGlvbnMtdGl0bGUiOiImIHtjbGFzc2lmaWNhdGlvbkNvdW50LCBwbHVyYWwsIG9uZSB7MSBtb3JlIGNsYXNzaWZpY2F0aW9ufSBvdGhlciB7e2NsYXNzaWZpY2F0aW9uQ291bnR9IG1vcmUgY2xhc3NpZmljYXRpb25zfX0iLCJhZGRpdGlvbmFsLWNsYXNzaWZpY2F0aW9ucy1kZXNjcmlwdGlvbiI6IiYge2NsYXNzaWZpY2F0aW9uQ291bnQsIHBsdXJhbCwgb25lIHsxIG1vcmUgY2xhc3NpZmljYXRpb259IG90aGVyIHt7Y2xhc3NpZmljYXRpb25Db3VudH0gbW9yZSBjbGFzc2lmaWNhdGlvbnN9fSIsInBhZ2UtbnVtYmVyLXRleHQiOiIoUGFnZSB7cGFnZX0pIiwibm9wYXJhbXMtdGl0bGUiOiJKb2JzIGluIHtjb3VudHJ5fSAtIHttb250aH0ge3llYXJ9e3BhZ2VOdW1iZXJUZXh0fSB8IHticmFuZH0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJjb3VudHJ5LXRpdGxlIjoiQXVzdHJhbGlhIiwiamFuLXRpdGxlIjoiSmFuIiwiZmViLXRpdGxlIjoiRmViIiwibWFyLXRpdGxlIjoiTWFyIiwiYXByLXRpdGxlIjoiQXByIiwibWF5LXRpdGxlIjoiTWF5IiwianVuLXRpdGxlIjoiSnVuIiwianVsLXRpdGxlIjoiSnVsIiwiYXVnLXRpdGxlIjoiQXVnIiwic2VwLXRpdGxlIjoiU2VwIiwib2N0LXRpdGxlIjoiT2N0Iiwibm92LXRpdGxlIjoiTm92IiwiZGVjLXRpdGxlIjoiRGVjIiwiYWRkaXRpb25hbC1jbGFzc2lmaWNhdGlvbnMtdGl0bGUiOiImIHtjbGFzc2lmaWNhdGlvbkNvdW50LCBwbHVyYWwsIG9uZSB7MSBtb3JlIGNsYXNzaWZpY2F0aW9ufSBvdGhlciB7e2NsYXNzaWZpY2F0aW9uQ291bnR9IG1vcmUgY2xhc3NpZmljYXRpb25zfX0iLCJhZGRpdGlvbmFsLWNsYXNzaWZpY2F0aW9ucy1kZXNjcmlwdGlvbiI6IiYge2NsYXNzaWZpY2F0aW9uQ291bnQsIHBsdXJhbCwgb25lIHsxIG1vcmUgY2xhc3NpZmljYXRpb259IG90aGVyIHt7Y2xhc3NpZmljYXRpb25Db3VudH0gbW9yZSBjbGFzc2lmaWNhdGlvbnN9fSIsInBhZ2UtbnVtYmVyLXRleHQiOiIoUGFnZSB7cGFnZX0pIiwibm9wYXJhbXMtdGl0bGUiOiJKb2JzIGluIHtjb3VudHJ5fSAtIHttb250aH0ge3llYXJ9e3BhZ2VOdW1iZXJUZXh0fSB8IHticmFuZH0ifQ==!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJjb3VudHJ5LXRpdGxlIjoiTmV3IFplYWxhbmQiLCJqYW4tdGl0bGUiOiJKYW4iLCJmZWItdGl0bGUiOiJGZWIiLCJtYXItdGl0bGUiOiJNYXIiLCJhcHItdGl0bGUiOiJBcHIiLCJtYXktdGl0bGUiOiJNYXkiLCJqdW4tdGl0bGUiOiJKdW4iLCJqdWwtdGl0bGUiOiJKdWwiLCJhdWctdGl0bGUiOiJBdWciLCJzZXAtdGl0bGUiOiJTZXAiLCJvY3QtdGl0bGUiOiJPY3QiLCJub3YtdGl0bGUiOiJOb3YiLCJkZWMtdGl0bGUiOiJEZWMiLCJhZGRpdGlvbmFsLWNsYXNzaWZpY2F0aW9ucy10aXRsZSI6IiYge2NsYXNzaWZpY2F0aW9uQ291bnQsIHBsdXJhbCwgb25lIHsxIG1vcmUgY2xhc3NpZmljYXRpb259IG90aGVyIHt7Y2xhc3NpZmljYXRpb25Db3VudH0gbW9yZSBjbGFzc2lmaWNhdGlvbnN9fSIsImFkZGl0aW9uYWwtY2xhc3NpZmljYXRpb25zLWRlc2NyaXB0aW9uIjoiJiB7Y2xhc3NpZmljYXRpb25Db3VudCwgcGx1cmFsLCBvbmUgezEgbW9yZSBjbGFzc2lmaWNhdGlvbn0gb3RoZXIge3tjbGFzc2lmaWNhdGlvbkNvdW50fSBtb3JlIGNsYXNzaWZpY2F0aW9uc319IiwicGFnZS1udW1iZXItdGV4dCI6IihQYWdlIHtwYWdlfSkiLCJub3BhcmFtcy10aXRsZSI6IkpvYnMgaW4ge2NvdW50cnl9IC0ge21vbnRofSB7eWVhcn17cGFnZU51bWJlclRleHR9IHwge2JyYW5kfSJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJjb3VudHJ5LXRpdGxlIjoiTmV3IFplYWxhbmQiLCJqYW4tdGl0bGUiOiJKYW4iLCJmZWItdGl0bGUiOiJGZWIiLCJtYXItdGl0bGUiOiJNYXIiLCJhcHItdGl0bGUiOiJBcHIiLCJtYXktdGl0bGUiOiJNYXkiLCJqdW4tdGl0bGUiOiJKdW4iLCJqdWwtdGl0bGUiOiJKdWwiLCJhdWctdGl0bGUiOiJBdWciLCJzZXAtdGl0bGUiOiJTZXAiLCJvY3QtdGl0bGUiOiJPY3QiLCJub3YtdGl0bGUiOiJOb3YiLCJkZWMtdGl0bGUiOiJEZWMiLCJhZGRpdGlvbmFsLWNsYXNzaWZpY2F0aW9ucy10aXRsZSI6IiYge2NsYXNzaWZpY2F0aW9uQ291bnQsIHBsdXJhbCwgb25lIHsxIG1vcmUgY2xhc3NpZmljYXRpb259IG90aGVyIHt7Y2xhc3NpZmljYXRpb25Db3VudH0gbW9yZSBjbGFzc2lmaWNhdGlvbnN9fSIsImFkZGl0aW9uYWwtY2xhc3NpZmljYXRpb25zLWRlc2NyaXB0aW9uIjoiJiB7Y2xhc3NpZmljYXRpb25Db3VudCwgcGx1cmFsLCBvbmUgezEgbW9yZSBjbGFzc2lmaWNhdGlvbn0gb3RoZXIge3tjbGFzc2lmaWNhdGlvbkNvdW50fSBtb3JlIGNsYXNzaWZpY2F0aW9uc319IiwicGFnZS1udW1iZXItdGV4dCI6IihQYWdlIHtwYWdlfSkiLCJub3BhcmFtcy10aXRsZSI6IkpvYnMgaW4ge2NvdW50cnl9IC0ge21vbnRofSB7eWVhcn17cGFnZU51bWJlclRleHR9IHwge2JyYW5kfSJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJjb3VudHJ5LXRpdGxlIjoiSG9uZyBLb25nIiwiamFuLXRpdGxlIjoiSmFuIiwiZmViLXRpdGxlIjoiRmViIiwibWFyLXRpdGxlIjoiTWFyIiwiYXByLXRpdGxlIjoiQXByIiwibWF5LXRpdGxlIjoiTWF5IiwianVuLXRpdGxlIjoiSnVuIiwianVsLXRpdGxlIjoiSnVsIiwiYXVnLXRpdGxlIjoiQXVnIiwic2VwLXRpdGxlIjoiU2VwIiwib2N0LXRpdGxlIjoiT2N0Iiwibm92LXRpdGxlIjoiTm92IiwiZGVjLXRpdGxlIjoiRGVjIiwiYWRkaXRpb25hbC1jbGFzc2lmaWNhdGlvbnMtdGl0bGUiOiImIHtjbGFzc2lmaWNhdGlvbkNvdW50LCBwbHVyYWwsIG9uZSB7MSBtb3JlIGNsYXNzaWZpY2F0aW9ufSBvdGhlciB7e2NsYXNzaWZpY2F0aW9uQ291bnR9IG1vcmUgY2xhc3NpZmljYXRpb25zfX0iLCJhZGRpdGlvbmFsLWNsYXNzaWZpY2F0aW9ucy1kZXNjcmlwdGlvbiI6IiYge2NsYXNzaWZpY2F0aW9uQ291bnQsIHBsdXJhbCwgb25lIHsxIG1vcmUgY2xhc3NpZmljYXRpb259IG90aGVyIHt7Y2xhc3NpZmljYXRpb25Db3VudH0gbW9yZSBjbGFzc2lmaWNhdGlvbnN9fSIsInBhZ2UtbnVtYmVyLXRleHQiOiIoUGFnZSB7cGFnZX0pIiwibm9wYXJhbXMtdGl0bGUiOiJKb2JzIGluIHtjb3VudHJ5fSAtIHttb250aH0ge3llYXJ9e3BhZ2VOdW1iZXJUZXh0fSB8IHticmFuZH0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJjb3VudHJ5LXRpdGxlIjoiSG9uZyBLb25nIiwiamFuLXRpdGxlIjoiSmFuIiwiZmViLXRpdGxlIjoiRmViIiwibWFyLXRpdGxlIjoiTWFyIiwiYXByLXRpdGxlIjoiQXByIiwibWF5LXRpdGxlIjoiTWF5IiwianVuLXRpdGxlIjoiSnVuIiwianVsLXRpdGxlIjoiSnVsIiwiYXVnLXRpdGxlIjoiQXVnIiwic2VwLXRpdGxlIjoiU2VwIiwib2N0LXRpdGxlIjoiT2N0Iiwibm92LXRpdGxlIjoiTm92IiwiZGVjLXRpdGxlIjoiRGVjIiwiYWRkaXRpb25hbC1jbGFzc2lmaWNhdGlvbnMtdGl0bGUiOiImIHtjbGFzc2lmaWNhdGlvbkNvdW50LCBwbHVyYWwsIG9uZSB7MSBtb3JlIGNsYXNzaWZpY2F0aW9ufSBvdGhlciB7e2NsYXNzaWZpY2F0aW9uQ291bnR9IG1vcmUgY2xhc3NpZmljYXRpb25zfX0iLCJhZGRpdGlvbmFsLWNsYXNzaWZpY2F0aW9ucy1kZXNjcmlwdGlvbiI6IiYge2NsYXNzaWZpY2F0aW9uQ291bnQsIHBsdXJhbCwgb25lIHsxIG1vcmUgY2xhc3NpZmljYXRpb259IG90aGVyIHt7Y2xhc3NpZmljYXRpb25Db3VudH0gbW9yZSBjbGFzc2lmaWNhdGlvbnN9fSIsInBhZ2UtbnVtYmVyLXRleHQiOiIoUGFnZSB7cGFnZX0pIiwibm9wYXJhbXMtdGl0bGUiOiJKb2JzIGluIHtjb3VudHJ5fSAtIHttb250aH0ge3llYXJ9e3BhZ2VOdW1iZXJUZXh0fSB8IHticmFuZH0ifQ==!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJjb3VudHJ5LXRpdGxlIjoiVGhhaWxhbmQiLCJqYW4tdGl0bGUiOiJKYW4iLCJmZWItdGl0bGUiOiJGZWIiLCJtYXItdGl0bGUiOiJNYXIiLCJhcHItdGl0bGUiOiJBcHIiLCJtYXktdGl0bGUiOiJNYXkiLCJqdW4tdGl0bGUiOiJKdW4iLCJqdWwtdGl0bGUiOiJKdWwiLCJhdWctdGl0bGUiOiJBdWciLCJzZXAtdGl0bGUiOiJTZXAiLCJvY3QtdGl0bGUiOiJPY3QiLCJub3YtdGl0bGUiOiJOb3YiLCJkZWMtdGl0bGUiOiJEZWMiLCJhZGRpdGlvbmFsLWNsYXNzaWZpY2F0aW9ucy10aXRsZSI6IiYge2NsYXNzaWZpY2F0aW9uQ291bnQsIHBsdXJhbCwgb25lIHsxIG1vcmUgY2xhc3NpZmljYXRpb259IG90aGVyIHt7Y2xhc3NpZmljYXRpb25Db3VudH0gbW9yZSBjbGFzc2lmaWNhdGlvbnN9fSIsImFkZGl0aW9uYWwtY2xhc3NpZmljYXRpb25zLWRlc2NyaXB0aW9uIjoiJiB7Y2xhc3NpZmljYXRpb25Db3VudCwgcGx1cmFsLCBvbmUgezEgbW9yZSBjbGFzc2lmaWNhdGlvbn0gb3RoZXIge3tjbGFzc2lmaWNhdGlvbkNvdW50fSBtb3JlIGNsYXNzaWZpY2F0aW9uc319IiwicGFnZS1udW1iZXItdGV4dCI6IihQYWdlIHtwYWdlfSkiLCJub3BhcmFtcy10aXRsZSI6IkpvYnMgaW4ge2NvdW50cnl9IC0ge21vbnRofSB7eWVhcn17cGFnZU51bWJlclRleHR9IHwge2JyYW5kfSJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJjb3VudHJ5LXRpdGxlIjoiVGhhaWxhbmQiLCJqYW4tdGl0bGUiOiJKYW4iLCJmZWItdGl0bGUiOiJGZWIiLCJtYXItdGl0bGUiOiJNYXIiLCJhcHItdGl0bGUiOiJBcHIiLCJtYXktdGl0bGUiOiJNYXkiLCJqdW4tdGl0bGUiOiJKdW4iLCJqdWwtdGl0bGUiOiJKdWwiLCJhdWctdGl0bGUiOiJBdWciLCJzZXAtdGl0bGUiOiJTZXAiLCJvY3QtdGl0bGUiOiJPY3QiLCJub3YtdGl0bGUiOiJOb3YiLCJkZWMtdGl0bGUiOiJEZWMiLCJhZGRpdGlvbmFsLWNsYXNzaWZpY2F0aW9ucy10aXRsZSI6IiYge2NsYXNzaWZpY2F0aW9uQ291bnQsIHBsdXJhbCwgb25lIHsxIG1vcmUgY2xhc3NpZmljYXRpb259IG90aGVyIHt7Y2xhc3NpZmljYXRpb25Db3VudH0gbW9yZSBjbGFzc2lmaWNhdGlvbnN9fSIsImFkZGl0aW9uYWwtY2xhc3NpZmljYXRpb25zLWRlc2NyaXB0aW9uIjoiJiB7Y2xhc3NpZmljYXRpb25Db3VudCwgcGx1cmFsLCBvbmUgezEgbW9yZSBjbGFzc2lmaWNhdGlvbn0gb3RoZXIge3tjbGFzc2lmaWNhdGlvbkNvdW50fSBtb3JlIGNsYXNzaWZpY2F0aW9uc319IiwicGFnZS1udW1iZXItdGV4dCI6IihQYWdlIHtwYWdlfSkiLCJub3BhcmFtcy10aXRsZSI6IkpvYnMgaW4ge2NvdW50cnl9IC0ge21vbnRofSB7eWVhcn17cGFnZU51bWJlclRleHR9IHwge2JyYW5kfSJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJjb3VudHJ5LXRpdGxlIjoiSW5kb25lc2lhIiwiamFuLXRpdGxlIjoiSmFuIiwiZmViLXRpdGxlIjoiRmViIiwibWFyLXRpdGxlIjoiTWFyIiwiYXByLXRpdGxlIjoiQXByIiwibWF5LXRpdGxlIjoiTWF5IiwianVuLXRpdGxlIjoiSnVuIiwianVsLXRpdGxlIjoiSnVsIiwiYXVnLXRpdGxlIjoiQXVnIiwic2VwLXRpdGxlIjoiU2VwIiwib2N0LXRpdGxlIjoiT2N0Iiwibm92LXRpdGxlIjoiTm92IiwiZGVjLXRpdGxlIjoiRGVjIiwiYWRkaXRpb25hbC1jbGFzc2lmaWNhdGlvbnMtdGl0bGUiOiImIHtjbGFzc2lmaWNhdGlvbkNvdW50LCBwbHVyYWwsIG9uZSB7MSBtb3JlIGNsYXNzaWZpY2F0aW9ufSBvdGhlciB7e2NsYXNzaWZpY2F0aW9uQ291bnR9IG1vcmUgY2xhc3NpZmljYXRpb25zfX0iLCJhZGRpdGlvbmFsLWNsYXNzaWZpY2F0aW9ucy1kZXNjcmlwdGlvbiI6IiYge2NsYXNzaWZpY2F0aW9uQ291bnQsIHBsdXJhbCwgb25lIHsxIG1vcmUgY2xhc3NpZmljYXRpb259IG90aGVyIHt7Y2xhc3NpZmljYXRpb25Db3VudH0gbW9yZSBjbGFzc2lmaWNhdGlvbnN9fSIsInBhZ2UtbnVtYmVyLXRleHQiOiIoUGFnZSB7cGFnZX0pIiwibm9wYXJhbXMtdGl0bGUiOiJKb2JzIGluIHtjb3VudHJ5fSAtIHttb250aH0ge3llYXJ9e3BhZ2VOdW1iZXJUZXh0fSB8IHticmFuZH0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJjb3VudHJ5LXRpdGxlIjoiSW5kb25lc2lhIiwiamFuLXRpdGxlIjoiSmFuIiwiZmViLXRpdGxlIjoiRmViIiwibWFyLXRpdGxlIjoiTWFyIiwiYXByLXRpdGxlIjoiQXByIiwibWF5LXRpdGxlIjoiTWF5IiwianVuLXRpdGxlIjoiSnVuIiwianVsLXRpdGxlIjoiSnVsIiwiYXVnLXRpdGxlIjoiQXVnIiwic2VwLXRpdGxlIjoiU2VwIiwib2N0LXRpdGxlIjoiT2N0Iiwibm92LXRpdGxlIjoiTm92IiwiZGVjLXRpdGxlIjoiRGVjIiwiYWRkaXRpb25hbC1jbGFzc2lmaWNhdGlvbnMtdGl0bGUiOiImIHtjbGFzc2lmaWNhdGlvbkNvdW50LCBwbHVyYWwsIG9uZSB7MSBtb3JlIGNsYXNzaWZpY2F0aW9ufSBvdGhlciB7e2NsYXNzaWZpY2F0aW9uQ291bnR9IG1vcmUgY2xhc3NpZmljYXRpb25zfX0iLCJhZGRpdGlvbmFsLWNsYXNzaWZpY2F0aW9ucy1kZXNjcmlwdGlvbiI6IiYge2NsYXNzaWZpY2F0aW9uQ291bnQsIHBsdXJhbCwgb25lIHsxIG1vcmUgY2xhc3NpZmljYXRpb259IG90aGVyIHt7Y2xhc3NpZmljYXRpb25Db3VudH0gbW9yZSBjbGFzc2lmaWNhdGlvbnN9fSIsInBhZ2UtbnVtYmVyLXRleHQiOiIoUGFnZSB7cGFnZX0pIiwibm9wYXJhbXMtdGl0bGUiOiJKb2JzIGluIHtjb3VudHJ5fSAtIHttb250aH0ge3llYXJ9e3BhZ2VOdW1iZXJUZXh0fSB8IHticmFuZH0ifQ==!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJjb3VudHJ5LXRpdGxlIjoiU2luZ2Fwb3JlIiwiamFuLXRpdGxlIjoiSmFuIiwiZmViLXRpdGxlIjoiRmViIiwibWFyLXRpdGxlIjoiTWFyIiwiYXByLXRpdGxlIjoiQXByIiwibWF5LXRpdGxlIjoiTWF5IiwianVuLXRpdGxlIjoiSnVuIiwianVsLXRpdGxlIjoiSnVsIiwiYXVnLXRpdGxlIjoiQXVnIiwic2VwLXRpdGxlIjoiU2VwIiwib2N0LXRpdGxlIjoiT2N0Iiwibm92LXRpdGxlIjoiTm92IiwiZGVjLXRpdGxlIjoiRGVjIiwiYWRkaXRpb25hbC1jbGFzc2lmaWNhdGlvbnMtdGl0bGUiOiImIHtjbGFzc2lmaWNhdGlvbkNvdW50LCBwbHVyYWwsIG9uZSB7MSBtb3JlIGNsYXNzaWZpY2F0aW9ufSBvdGhlciB7e2NsYXNzaWZpY2F0aW9uQ291bnR9IG1vcmUgY2xhc3NpZmljYXRpb25zfX0iLCJhZGRpdGlvbmFsLWNsYXNzaWZpY2F0aW9ucy1kZXNjcmlwdGlvbiI6IiYge2NsYXNzaWZpY2F0aW9uQ291bnQsIHBsdXJhbCwgb25lIHsxIG1vcmUgY2xhc3NpZmljYXRpb259IG90aGVyIHt7Y2xhc3NpZmljYXRpb25Db3VudH0gbW9yZSBjbGFzc2lmaWNhdGlvbnN9fSIsInBhZ2UtbnVtYmVyLXRleHQiOiIoUGFnZSB7cGFnZX0pIiwibm9wYXJhbXMtdGl0bGUiOiJKb2JzIGluIHtjb3VudHJ5fSAtIHttb250aH0ge3llYXJ9e3BhZ2VOdW1iZXJUZXh0fSB8IHticmFuZH0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJjb3VudHJ5LXRpdGxlIjoiU2luZ2Fwb3JlIiwiamFuLXRpdGxlIjoiSmFuIiwiZmViLXRpdGxlIjoiRmViIiwibWFyLXRpdGxlIjoiTWFyIiwiYXByLXRpdGxlIjoiQXByIiwibWF5LXRpdGxlIjoiTWF5IiwianVuLXRpdGxlIjoiSnVuIiwianVsLXRpdGxlIjoiSnVsIiwiYXVnLXRpdGxlIjoiQXVnIiwic2VwLXRpdGxlIjoiU2VwIiwib2N0LXRpdGxlIjoiT2N0Iiwibm92LXRpdGxlIjoiTm92IiwiZGVjLXRpdGxlIjoiRGVjIiwiYWRkaXRpb25hbC1jbGFzc2lmaWNhdGlvbnMtdGl0bGUiOiImIHtjbGFzc2lmaWNhdGlvbkNvdW50LCBwbHVyYWwsIG9uZSB7MSBtb3JlIGNsYXNzaWZpY2F0aW9ufSBvdGhlciB7e2NsYXNzaWZpY2F0aW9uQ291bnR9IG1vcmUgY2xhc3NpZmljYXRpb25zfX0iLCJhZGRpdGlvbmFsLWNsYXNzaWZpY2F0aW9ucy1kZXNjcmlwdGlvbiI6IiYge2NsYXNzaWZpY2F0aW9uQ291bnQsIHBsdXJhbCwgb25lIHsxIG1vcmUgY2xhc3NpZmljYXRpb259IG90aGVyIHt7Y2xhc3NpZmljYXRpb25Db3VudH0gbW9yZSBjbGFzc2lmaWNhdGlvbnN9fSIsInBhZ2UtbnVtYmVyLXRleHQiOiIoUGFnZSB7cGFnZX0pIiwibm9wYXJhbXMtdGl0bGUiOiJKb2JzIGluIHtjb3VudHJ5fSAtIHttb250aH0ge3llYXJ9e3BhZ2VOdW1iZXJUZXh0fSB8IHticmFuZH0ifQ==!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJjb3VudHJ5LXRpdGxlIjoiTWFsYXlzaWEiLCJqYW4tdGl0bGUiOiJKYW4iLCJmZWItdGl0bGUiOiJGZWIiLCJtYXItdGl0bGUiOiJNYXIiLCJhcHItdGl0bGUiOiJBcHIiLCJtYXktdGl0bGUiOiJNYXkiLCJqdW4tdGl0bGUiOiJKdW4iLCJqdWwtdGl0bGUiOiJKdWwiLCJhdWctdGl0bGUiOiJBdWciLCJzZXAtdGl0bGUiOiJTZXAiLCJvY3QtdGl0bGUiOiJPY3QiLCJub3YtdGl0bGUiOiJOb3YiLCJkZWMtdGl0bGUiOiJEZWMiLCJhZGRpdGlvbmFsLWNsYXNzaWZpY2F0aW9ucy10aXRsZSI6IiYge2NsYXNzaWZpY2F0aW9uQ291bnQsIHBsdXJhbCwgb25lIHsxIG1vcmUgY2xhc3NpZmljYXRpb259IG90aGVyIHt7Y2xhc3NpZmljYXRpb25Db3VudH0gbW9yZSBjbGFzc2lmaWNhdGlvbnN9fSIsImFkZGl0aW9uYWwtY2xhc3NpZmljYXRpb25zLWRlc2NyaXB0aW9uIjoiJiB7Y2xhc3NpZmljYXRpb25Db3VudCwgcGx1cmFsLCBvbmUgezEgbW9yZSBjbGFzc2lmaWNhdGlvbn0gb3RoZXIge3tjbGFzc2lmaWNhdGlvbkNvdW50fSBtb3JlIGNsYXNzaWZpY2F0aW9uc319IiwicGFnZS1udW1iZXItdGV4dCI6IihQYWdlIHtwYWdlfSkiLCJub3BhcmFtcy10aXRsZSI6IkpvYnMgaW4ge2NvdW50cnl9IC0ge21vbnRofSB7eWVhcn17cGFnZU51bWJlclRleHR9IHwge2JyYW5kfSJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJjb3VudHJ5LXRpdGxlIjoiTWFsYXlzaWEiLCJqYW4tdGl0bGUiOiJKYW4iLCJmZWItdGl0bGUiOiJGZWIiLCJtYXItdGl0bGUiOiJNYXIiLCJhcHItdGl0bGUiOiJBcHIiLCJtYXktdGl0bGUiOiJNYXkiLCJqdW4tdGl0bGUiOiJKdW4iLCJqdWwtdGl0bGUiOiJKdWwiLCJhdWctdGl0bGUiOiJBdWciLCJzZXAtdGl0bGUiOiJTZXAiLCJvY3QtdGl0bGUiOiJPY3QiLCJub3YtdGl0bGUiOiJOb3YiLCJkZWMtdGl0bGUiOiJEZWMiLCJhZGRpdGlvbmFsLWNsYXNzaWZpY2F0aW9ucy10aXRsZSI6IiYge2NsYXNzaWZpY2F0aW9uQ291bnQsIHBsdXJhbCwgb25lIHsxIG1vcmUgY2xhc3NpZmljYXRpb259IG90aGVyIHt7Y2xhc3NpZmljYXRpb25Db3VudH0gbW9yZSBjbGFzc2lmaWNhdGlvbnN9fSIsImFkZGl0aW9uYWwtY2xhc3NpZmljYXRpb25zLWRlc2NyaXB0aW9uIjoiJiB7Y2xhc3NpZmljYXRpb25Db3VudCwgcGx1cmFsLCBvbmUgezEgbW9yZSBjbGFzc2lmaWNhdGlvbn0gb3RoZXIge3tjbGFzc2lmaWNhdGlvbkNvdW50fSBtb3JlIGNsYXNzaWZpY2F0aW9uc319IiwicGFnZS1udW1iZXItdGV4dCI6IihQYWdlIHtwYWdlfSkiLCJub3BhcmFtcy10aXRsZSI6IkpvYnMgaW4ge2NvdW50cnl9IC0ge21vbnRofSB7eWVhcn17cGFnZU51bWJlclRleHR9IHwge2JyYW5kfSJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJjb3VudHJ5LXRpdGxlIjoiUGhpbGlwcGluZXMiLCJqYW4tdGl0bGUiOiJKYW4iLCJmZWItdGl0bGUiOiJGZWIiLCJtYXItdGl0bGUiOiJNYXIiLCJhcHItdGl0bGUiOiJBcHIiLCJtYXktdGl0bGUiOiJNYXkiLCJqdW4tdGl0bGUiOiJKdW4iLCJqdWwtdGl0bGUiOiJKdWwiLCJhdWctdGl0bGUiOiJBdWciLCJzZXAtdGl0bGUiOiJTZXAiLCJvY3QtdGl0bGUiOiJPY3QiLCJub3YtdGl0bGUiOiJOb3YiLCJkZWMtdGl0bGUiOiJEZWMiLCJhZGRpdGlvbmFsLWNsYXNzaWZpY2F0aW9ucy10aXRsZSI6IiYge2NsYXNzaWZpY2F0aW9uQ291bnQsIHBsdXJhbCwgb25lIHsxIG1vcmUgY2xhc3NpZmljYXRpb259IG90aGVyIHt7Y2xhc3NpZmljYXRpb25Db3VudH0gbW9yZSBjbGFzc2lmaWNhdGlvbnN9fSIsImFkZGl0aW9uYWwtY2xhc3NpZmljYXRpb25zLWRlc2NyaXB0aW9uIjoiJiB7Y2xhc3NpZmljYXRpb25Db3VudCwgcGx1cmFsLCBvbmUgezEgbW9yZSBjbGFzc2lmaWNhdGlvbn0gb3RoZXIge3tjbGFzc2lmaWNhdGlvbkNvdW50fSBtb3JlIGNsYXNzaWZpY2F0aW9uc319IiwicGFnZS1udW1iZXItdGV4dCI6IihQYWdlIHtwYWdlfSkiLCJub3BhcmFtcy10aXRsZSI6IkpvYnMgaW4ge2NvdW50cnl9IC0ge21vbnRofSB7eWVhcn17cGFnZU51bWJlclRleHR9IHwge2JyYW5kfSJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJjb3VudHJ5LXRpdGxlIjoiUGhpbGlwcGluZXMiLCJqYW4tdGl0bGUiOiJKYW4iLCJmZWItdGl0bGUiOiJGZWIiLCJtYXItdGl0bGUiOiJNYXIiLCJhcHItdGl0bGUiOiJBcHIiLCJtYXktdGl0bGUiOiJNYXkiLCJqdW4tdGl0bGUiOiJKdW4iLCJqdWwtdGl0bGUiOiJKdWwiLCJhdWctdGl0bGUiOiJBdWciLCJzZXAtdGl0bGUiOiJTZXAiLCJvY3QtdGl0bGUiOiJPY3QiLCJub3YtdGl0bGUiOiJOb3YiLCJkZWMtdGl0bGUiOiJEZWMiLCJhZGRpdGlvbmFsLWNsYXNzaWZpY2F0aW9ucy10aXRsZSI6IiYge2NsYXNzaWZpY2F0aW9uQ291bnQsIHBsdXJhbCwgb25lIHsxIG1vcmUgY2xhc3NpZmljYXRpb259IG90aGVyIHt7Y2xhc3NpZmljYXRpb25Db3VudH0gbW9yZSBjbGFzc2lmaWNhdGlvbnN9fSIsImFkZGl0aW9uYWwtY2xhc3NpZmljYXRpb25zLWRlc2NyaXB0aW9uIjoiJiB7Y2xhc3NpZmljYXRpb25Db3VudCwgcGx1cmFsLCBvbmUgezEgbW9yZSBjbGFzc2lmaWNhdGlvbn0gb3RoZXIge3tjbGFzc2lmaWNhdGlvbkNvdW50fSBtb3JlIGNsYXNzaWZpY2F0aW9uc319IiwicGFnZS1udW1iZXItdGV4dCI6IihQYWdlIHtwYWdlfSkiLCJub3BhcmFtcy10aXRsZSI6IkpvYnMgaW4ge2NvdW50cnl9IC0ge21vbnRofSB7eWVhcn17cGFnZU51bWJlclRleHR9IHwge2JyYW5kfSJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJjb3VudHJ5LXRpdGxlIjoiSW5kb25lc2lhIiwiamFuLXRpdGxlIjoiSmFuIiwiZmViLXRpdGxlIjoiRmViIiwibWFyLXRpdGxlIjoiTWFyIiwiYXByLXRpdGxlIjoiQXByIiwibWF5LXRpdGxlIjoiTWF5IiwianVuLXRpdGxlIjoiSnVuIiwianVsLXRpdGxlIjoiSnVsIiwiYXVnLXRpdGxlIjoiQXVnIiwic2VwLXRpdGxlIjoiU2VwIiwib2N0LXRpdGxlIjoiT2N0Iiwibm92LXRpdGxlIjoiTm92IiwiZGVjLXRpdGxlIjoiRGVjIiwiYWRkaXRpb25hbC1jbGFzc2lmaWNhdGlvbnMtdGl0bGUiOiImIHtjbGFzc2lmaWNhdGlvbkNvdW50LCBwbHVyYWwsIG9uZSB7MSBtb3JlIGNsYXNzaWZpY2F0aW9ufSBvdGhlciB7e2NsYXNzaWZpY2F0aW9uQ291bnR9IG1vcmUgY2xhc3NpZmljYXRpb25zfX0iLCJhZGRpdGlvbmFsLWNsYXNzaWZpY2F0aW9ucy1kZXNjcmlwdGlvbiI6IiYge2NsYXNzaWZpY2F0aW9uQ291bnQsIHBsdXJhbCwgb25lIHsxIG1vcmUgY2xhc3NpZmljYXRpb259IG90aGVyIHt7Y2xhc3NpZmljYXRpb25Db3VudH0gbW9yZSBjbGFzc2lmaWNhdGlvbnN9fSIsInBhZ2UtbnVtYmVyLXRleHQiOiIoUGFnZSB7cGFnZX0pIiwibm9wYXJhbXMtdGl0bGUiOiJKb2JzIGluIHtjb3VudHJ5fSAtIHttb250aH0ge3llYXJ9e3BhZ2VOdW1iZXJUZXh0fSB8IHticmFuZH0ifQ==!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJjb3VudHJ5LXRpdGxlIjoiSW5kb25lc2lhIiwiamFuLXRpdGxlIjoiSmFuIiwiZmViLXRpdGxlIjoiRmViIiwibWFyLXRpdGxlIjoiTWFyIiwiYXByLXRpdGxlIjoiQXByIiwibWF5LXRpdGxlIjoiTWF5IiwianVuLXRpdGxlIjoiSnVuIiwianVsLXRpdGxlIjoiSnVsIiwiYXVnLXRpdGxlIjoiQXVnIiwic2VwLXRpdGxlIjoiU2VwIiwib2N0LXRpdGxlIjoiT2N0Iiwibm92LXRpdGxlIjoiTm92IiwiZGVjLXRpdGxlIjoiRGVjIiwiYWRkaXRpb25hbC1jbGFzc2lmaWNhdGlvbnMtdGl0bGUiOiImIHtjbGFzc2lmaWNhdGlvbkNvdW50LCBwbHVyYWwsIG9uZSB7MSBtb3JlIGNsYXNzaWZpY2F0aW9ufSBvdGhlciB7e2NsYXNzaWZpY2F0aW9uQ291bnR9IG1vcmUgY2xhc3NpZmljYXRpb25zfX0iLCJhZGRpdGlvbmFsLWNsYXNzaWZpY2F0aW9ucy1kZXNjcmlwdGlvbiI6IiYge2NsYXNzaWZpY2F0aW9uQ291bnQsIHBsdXJhbCwgb25lIHsxIG1vcmUgY2xhc3NpZmljYXRpb259IG90aGVyIHt7Y2xhc3NpZmljYXRpb25Db3VudH0gbW9yZSBjbGFzc2lmaWNhdGlvbnN9fSIsInBhZ2UtbnVtYmVyLXRleHQiOiIoUGFnZSB7cGFnZX0pIiwibm9wYXJhbXMtdGl0bGUiOiJKb2JzIGluIHtjb3VudHJ5fSAtIHttb250aH0ge3llYXJ9e3BhZ2VOdW1iZXJUZXh0fSB8IHticmFuZH0ifQ==!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJjb3VudHJ5LXRpdGxlIjoiVGhhaWxhbmQiLCJqYW4tdGl0bGUiOiJKYW4iLCJmZWItdGl0bGUiOiJGZWIiLCJtYXItdGl0bGUiOiJNYXIiLCJhcHItdGl0bGUiOiJBcHIiLCJtYXktdGl0bGUiOiJNYXkiLCJqdW4tdGl0bGUiOiJKdW4iLCJqdWwtdGl0bGUiOiJKdWwiLCJhdWctdGl0bGUiOiJBdWciLCJzZXAtdGl0bGUiOiJTZXAiLCJvY3QtdGl0bGUiOiJPY3QiLCJub3YtdGl0bGUiOiJOb3YiLCJkZWMtdGl0bGUiOiJEZWMiLCJhZGRpdGlvbmFsLWNsYXNzaWZpY2F0aW9ucy10aXRsZSI6IiYge2NsYXNzaWZpY2F0aW9uQ291bnQsIHBsdXJhbCwgb25lIHsxIG1vcmUgY2xhc3NpZmljYXRpb259IG90aGVyIHt7Y2xhc3NpZmljYXRpb25Db3VudH0gbW9yZSBjbGFzc2lmaWNhdGlvbnN9fSIsImFkZGl0aW9uYWwtY2xhc3NpZmljYXRpb25zLWRlc2NyaXB0aW9uIjoiJiB7Y2xhc3NpZmljYXRpb25Db3VudCwgcGx1cmFsLCBvbmUgezEgbW9yZSBjbGFzc2lmaWNhdGlvbn0gb3RoZXIge3tjbGFzc2lmaWNhdGlvbkNvdW50fSBtb3JlIGNsYXNzaWZpY2F0aW9uc319IiwicGFnZS1udW1iZXItdGV4dCI6IihQYWdlIHtwYWdlfSkiLCJub3BhcmFtcy10aXRsZSI6IkpvYnMgaW4ge2NvdW50cnl9IC0ge21vbnRofSB7eWVhcn17cGFnZU51bWJlclRleHR9IHwge2JyYW5kfSJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJjb3VudHJ5LXRpdGxlIjoiVGhhaWxhbmQiLCJqYW4tdGl0bGUiOiJKYW4iLCJmZWItdGl0bGUiOiJGZWIiLCJtYXItdGl0bGUiOiJNYXIiLCJhcHItdGl0bGUiOiJBcHIiLCJtYXktdGl0bGUiOiJNYXkiLCJqdW4tdGl0bGUiOiJKdW4iLCJqdWwtdGl0bGUiOiJKdWwiLCJhdWctdGl0bGUiOiJBdWciLCJzZXAtdGl0bGUiOiJTZXAiLCJvY3QtdGl0bGUiOiJPY3QiLCJub3YtdGl0bGUiOiJOb3YiLCJkZWMtdGl0bGUiOiJEZWMiLCJhZGRpdGlvbmFsLWNsYXNzaWZpY2F0aW9ucy10aXRsZSI6IiYge2NsYXNzaWZpY2F0aW9uQ291bnQsIHBsdXJhbCwgb25lIHsxIG1vcmUgY2xhc3NpZmljYXRpb259IG90aGVyIHt7Y2xhc3NpZmljYXRpb25Db3VudH0gbW9yZSBjbGFzc2lmaWNhdGlvbnN9fSIsImFkZGl0aW9uYWwtY2xhc3NpZmljYXRpb25zLWRlc2NyaXB0aW9uIjoiJiB7Y2xhc3NpZmljYXRpb25Db3VudCwgcGx1cmFsLCBvbmUgezEgbW9yZSBjbGFzc2lmaWNhdGlvbn0gb3RoZXIge3tjbGFzc2lmaWNhdGlvbkNvdW50fSBtb3JlIGNsYXNzaWZpY2F0aW9uc319IiwicGFnZS1udW1iZXItdGV4dCI6IihQYWdlIHtwYWdlfSkiLCJub3BhcmFtcy10aXRsZSI6IkpvYnMgaW4ge2NvdW50cnl9IC0ge21vbnRofSB7eWVhcn17cGFnZU51bWJlclRleHR9IHwge2JyYW5kfSJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJjb3VudHJ5LXRpdGxlIjoiSW5kb25lc2lhIiwiamFuLXRpdGxlIjoiSmFudWFyaSIsImZlYi10aXRsZSI6IkZlYnJ1YXJpIiwibWFyLXRpdGxlIjoiTWFyZXQiLCJhcHItdGl0bGUiOiJBcHJpbCIsIm1heS10aXRsZSI6Ik1laSIsImp1bi10aXRsZSI6Ikp1bmkiLCJqdWwtdGl0bGUiOiJKdWxpIiwiYXVnLXRpdGxlIjoiQWd1c3R1cyIsInNlcC10aXRsZSI6IlNlcHRlbWJlciIsIm9jdC10aXRsZSI6Ik9rdG9iZXIiLCJub3YtdGl0bGUiOiJOb3ZlbWJlciIsImRlYy10aXRsZSI6IkRlc2VtYmVyIiwiYWRkaXRpb25hbC1jbGFzc2lmaWNhdGlvbnMtdGl0bGUiOiImIHtjbGFzc2lmaWNhdGlvbkNvdW50fSBrbGFzaWZpa2FzaSBsYWlubnlhIiwiYWRkaXRpb25hbC1jbGFzc2lmaWNhdGlvbnMtZGVzY3JpcHRpb24iOiImIHtjbGFzc2lmaWNhdGlvbkNvdW50fSBsZWJpaCBiYW55YWsga2xhc2lmaWthc2kiLCJwYWdlLW51bWJlci10ZXh0IjoiKEhhbGFtYW4ge3BhZ2V9KSIsIm5vcGFyYW1zLXRpdGxlIjoiTG93b25nYW4gS2VyamEgZGkge2NvdW50cnl9IC0ge21vbnRofSB7eWVhcn17cGFnZU51bWJlclRleHR9IHwge2JyYW5kfSJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJjb3VudHJ5LXRpdGxlIjoiSW5kb25lc2lhIiwiamFuLXRpdGxlIjoiSmFudWFyaSIsImZlYi10aXRsZSI6IkZlYnJ1YXJpIiwibWFyLXRpdGxlIjoiTWFyZXQiLCJhcHItdGl0bGUiOiJBcHJpbCIsIm1heS10aXRsZSI6Ik1laSIsImp1bi10aXRsZSI6Ikp1bmkiLCJqdWwtdGl0bGUiOiJKdWxpIiwiYXVnLXRpdGxlIjoiQWd1c3R1cyIsInNlcC10aXRsZSI6IlNlcHRlbWJlciIsIm9jdC10aXRsZSI6Ik9rdG9iZXIiLCJub3YtdGl0bGUiOiJOb3ZlbWJlciIsImRlYy10aXRsZSI6IkRlc2VtYmVyIiwiYWRkaXRpb25hbC1jbGFzc2lmaWNhdGlvbnMtdGl0bGUiOiImIHtjbGFzc2lmaWNhdGlvbkNvdW50fSBrbGFzaWZpa2FzaSBsYWlubnlhIiwiYWRkaXRpb25hbC1jbGFzc2lmaWNhdGlvbnMtZGVzY3JpcHRpb24iOiImIHtjbGFzc2lmaWNhdGlvbkNvdW50fSBsZWJpaCBiYW55YWsga2xhc2lmaWthc2kiLCJwYWdlLW51bWJlci10ZXh0IjoiKEhhbGFtYW4ge3BhZ2V9KSIsIm5vcGFyYW1zLXRpdGxlIjoiTG93b25nYW4gS2VyamEgZGkge2NvdW50cnl9IC0ge21vbnRofSB7eWVhcn17cGFnZU51bWJlclRleHR9IHwge2JyYW5kfSJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJjb3VudHJ5LXRpdGxlIjoi4LmE4LiX4LiiIiwiamFuLXRpdGxlIjoi4LihLuC4hC4iLCJmZWItdGl0bGUiOiLguIEu4LieLiIsIm1hci10aXRsZSI6IuC4oeC4tS7guIQuIiwiYXByLXRpdGxlIjoi4LmA4LihLuC4oi4iLCJtYXktdGl0bGUiOiLguJ4u4LiELiIsImp1bi10aXRsZSI6IuC4oeC4tC7guKIuIiwianVsLXRpdGxlIjoi4LiBLuC4hC4iLCJhdWctdGl0bGUiOiLguKou4LiELiIsInNlcC10aXRsZSI6IuC4gS7guKIuIiwib2N0LXRpdGxlIjoi4LiVLuC4hC4iLCJub3YtdGl0bGUiOiLguJ4u4LiiLiIsImRlYy10aXRsZSI6IuC4mC7guIQuIiwiYWRkaXRpb25hbC1jbGFzc2lmaWNhdGlvbnMtdGl0bGUiOiImIOC4reC4teC4gSB7Y2xhc3NpZmljYXRpb25Db3VudH0g4LiY4Li44Lij4LiB4Li04LiIIiwiYWRkaXRpb25hbC1jbGFzc2lmaWNhdGlvbnMtZGVzY3JpcHRpb24iOiImIHtjbGFzc2lmaWNhdGlvbkNvdW50fSDguKvguKHguKfguJTguIfguLLguJnguYDguJ7guLTguYjguKHguYDguJXguLTguKEiLCJwYWdlLW51bWJlci10ZXh0IjoiKOC4q+C4meC5ieC4siB7cGFnZX0pIiwibm9wYXJhbXMtdGl0bGUiOiLguIfguLLguJnguYPguJkge2NvdW50cnl9IC0ge21vbnRofSB7eWVhcn17cGFnZU51bWJlclRleHR9IHwge2JyYW5kfSJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJjb3VudHJ5LXRpdGxlIjoi4LmE4LiX4LiiIiwiamFuLXRpdGxlIjoi4LihLuC4hC4iLCJmZWItdGl0bGUiOiLguIEu4LieLiIsIm1hci10aXRsZSI6IuC4oeC4tS7guIQuIiwiYXByLXRpdGxlIjoi4LmA4LihLuC4oi4iLCJtYXktdGl0bGUiOiLguJ4u4LiELiIsImp1bi10aXRsZSI6IuC4oeC4tC7guKIuIiwianVsLXRpdGxlIjoi4LiBLuC4hC4iLCJhdWctdGl0bGUiOiLguKou4LiELiIsInNlcC10aXRsZSI6IuC4gS7guKIuIiwib2N0LXRpdGxlIjoi4LiVLuC4hC4iLCJub3YtdGl0bGUiOiLguJ4u4LiiLiIsImRlYy10aXRsZSI6IuC4mC7guIQuIiwiYWRkaXRpb25hbC1jbGFzc2lmaWNhdGlvbnMtdGl0bGUiOiImIOC4reC4teC4gSB7Y2xhc3NpZmljYXRpb25Db3VudH0g4LiY4Li44Lij4LiB4Li04LiIIiwiYWRkaXRpb25hbC1jbGFzc2lmaWNhdGlvbnMtZGVzY3JpcHRpb24iOiImIHtjbGFzc2lmaWNhdGlvbkNvdW50fSDguKvguKHguKfguJTguIfguLLguJnguYDguJ7guLTguYjguKHguYDguJXguLTguKEiLCJwYWdlLW51bWJlci10ZXh0IjoiKOC4q+C4meC5ieC4siB7cGFnZX0pIiwibm9wYXJhbXMtdGl0bGUiOiLguIfguLLguJnguYPguJkge2NvdW50cnl9IC0ge21vbnRofSB7eWVhcn17cGFnZU51bWJlclRleHR9IHwge2JyYW5kfSJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJjb3VudHJ5LXRpdGxlIjoiW+G6rMeax5rFoeG5r8WZxIPEg8aaw6zDrMSDxINdIiwiamFuLXRpdGxlIjoiW8S0xIPEg8SD4LiB4Li14LmJXSIsImZlYi10aXRsZSI6IlvGkeG6veG6veG6vcOfXSIsIm1hci10aXRsZSI6IlvhuYLEg8SDxIPFmV0iLCJhcHItdGl0bGUiOiJb4bqsxqXFmV0iLCJtYXktdGl0bGUiOiJb4bmCxIPEg8O9w71dIiwianVuLXRpdGxlIjoiW8S0x5rHmsea4LiB4Li14LmJXSIsImp1bC10aXRsZSI6IlvEtMeax5rHmsaaXSIsImF1Zy10aXRsZSI6IlvhuqzHmseax5rEo10iLCJzZXAtdGl0bGUiOiJbxaDhur3hur3hur3GpV0iLCJvY3QtdGl0bGUiOiJbw5bDp+G5r10iLCJub3YtdGl0bGUiOiJbw5HDtsO2w7bhub1dIiwiZGVjLXRpdGxlIjoiW+G4iuG6veG6veG6vcOnXSIsImFkZGl0aW9uYWwtY2xhc3NpZmljYXRpb25zLXRpdGxlIjoiWyYge2NsYXNzaWZpY2F0aW9uQ291bnQscGx1cmFsLG9uZXsxIG3MgsO2w7bDtsWZ4bq94bq94bq9IMOnxprEg8SDxIPFocWhw6zDrMOsxpLDrMOsw6zDp8SDxIPEg+G5r8Osw6zDrMO2w7bDtuC4geC4teC5iX0gb3RoZXJ7e2NsYXNzaWZpY2F0aW9uQ291bnR9IG3MgsO2w7bDtsWZ4bq94bq94bq9IMOnxprEg8SDxIPFocWhw6zDrMOsxpLDrMOsw6zDp8SDxIPEg+G5r8Osw6zDrMO2w7bDtuC4geC4teC5icWhfX1dIiwiYWRkaXRpb25hbC1jbGFzc2lmaWNhdGlvbnMtZGVzY3JpcHRpb24iOiJbJiB7Y2xhc3NpZmljYXRpb25Db3VudCxwbHVyYWwsb25lezEgbcyCw7bDtsO2xZnhur3hur3hur0gw6fGmsSDxIPEg8WhxaHDrMOsw6zGksOsw6zDrMOnxIPEg8SD4bmvw6zDrMOsw7bDtsO24LiB4Li14LmJfSBvdGhlcnt7Y2xhc3NpZmljYXRpb25Db3VudH0gbcyCw7bDtsO2xZnhur3hur3hur0gw6fGmsSDxIPEg8WhxaHDrMOsw6zGksOsw6zDrMOnxIPEg8SD4bmvw6zDrMOsw7bDtsO24LiB4Li14LmJxaF9fV0iLCJwYWdlLW51bWJlci10ZXh0IjoiWyjGpMSDxIPEg8Sj4bq94bq94bq9IHtwYWdlfSldIiwibm9wYXJhbXMtdGl0bGUiOiJbxLTDtsO2w7bDn8WhIMOsw6zDrOC4geC4teC5iSB7Y291bnRyeX0gLSB7bW9udGh9IHt5ZWFyfXtwYWdlTnVtYmVyVGV4dH0gfCB7YnJhbmR9XSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJjb3VudHJ5LXRpdGxlIjoiW+G6rMeax5rFoeG5r8WZxIPEg8aaw6zDrMSDxINdIiwiamFuLXRpdGxlIjoiW8S0xIPEg8SD4LiB4Li14LmJXSIsImZlYi10aXRsZSI6IlvGkeG6veG6veG6vcOfXSIsIm1hci10aXRsZSI6IlvhuYLEg8SDxIPFmV0iLCJhcHItdGl0bGUiOiJb4bqsxqXFmV0iLCJtYXktdGl0bGUiOiJb4bmCxIPEg8O9w71dIiwianVuLXRpdGxlIjoiW8S0x5rHmsea4LiB4Li14LmJXSIsImp1bC10aXRsZSI6IlvEtMeax5rHmsaaXSIsImF1Zy10aXRsZSI6IlvhuqzHmseax5rEo10iLCJzZXAtdGl0bGUiOiJbxaDhur3hur3hur3GpV0iLCJvY3QtdGl0bGUiOiJbw5bDp+G5r10iLCJub3YtdGl0bGUiOiJbw5HDtsO2w7bhub1dIiwiZGVjLXRpdGxlIjoiW+G4iuG6veG6veG6vcOnXSIsImFkZGl0aW9uYWwtY2xhc3NpZmljYXRpb25zLXRpdGxlIjoiWyYge2NsYXNzaWZpY2F0aW9uQ291bnQscGx1cmFsLG9uZXsxIG3MgsO2w7bDtsWZ4bq94bq94bq9IMOnxprEg8SDxIPFocWhw6zDrMOsxpLDrMOsw6zDp8SDxIPEg+G5r8Osw6zDrMO2w7bDtuC4geC4teC5iX0gb3RoZXJ7e2NsYXNzaWZpY2F0aW9uQ291bnR9IG3MgsO2w7bDtsWZ4bq94bq94bq9IMOnxprEg8SDxIPFocWhw6zDrMOsxpLDrMOsw6zDp8SDxIPEg+G5r8Osw6zDrMO2w7bDtuC4geC4teC5icWhfX1dIiwiYWRkaXRpb25hbC1jbGFzc2lmaWNhdGlvbnMtZGVzY3JpcHRpb24iOiJbJiB7Y2xhc3NpZmljYXRpb25Db3VudCxwbHVyYWwsb25lezEgbcyCw7bDtsO2xZnhur3hur3hur0gw6fGmsSDxIPEg8WhxaHDrMOsw6zGksOsw6zDrMOnxIPEg8SD4bmvw6zDrMOsw7bDtsO24LiB4Li14LmJfSBvdGhlcnt7Y2xhc3NpZmljYXRpb25Db3VudH0gbcyCw7bDtsO2xZnhur3hur3hur0gw6fGmsSDxIPEg8WhxaHDrMOsw6zGksOsw6zDrMOnxIPEg8SD4bmvw6zDrMOsw7bDtsO24LiB4Li14LmJxaF9fV0iLCJwYWdlLW51bWJlci10ZXh0IjoiWyjGpMSDxIPEg8Sj4bq94bq94bq9IHtwYWdlfSldIiwibm9wYXJhbXMtdGl0bGUiOiJbxLTDtsO2w7bDn8WhIMOsw6zDrOC4geC4teC5iSB7Y291bnRyeX0gLSB7bW9udGh9IHt5ZWFyfXtwYWdlTnVtYmVyVGV4dH0gfCB7YnJhbmR9XSJ9!"
        )
      )
      });
  
      export { translations as default };
    