import type {
  SearchLocation,
  SearchParams,
  SearchResultLocationV5,
} from '@seek/chalice-types';
import type { Country, Locale } from '@seek/melways-sites';
import {
  filterObject,
  formatWorkType,
  getClassDescription,
  getMonthAndYear,
  isAU,
} from '@seek/seek-jobs-seo';
import get from 'lodash/get';
import size from 'lodash/size';
import startCase from 'lodash/startCase';

import { getWorkArrangements } from 'src/modules/refine-job-search';

import { titleOverride } from '../titleOverride/titleOverride';
import type {
  ReplaceableKeyValues,
  TitleType,
  TitleTypeValues,
} from '../titleOverride/type';
import { toProperCase } from '../utils';

type CountryTextType = 'Australia' | 'New Zealand';

interface TParams {
  keywords?: string;
  classification?: Array<string | Record<string, unknown>>;
  subclassification?: string[];
  worktype?: string;
  workarrangement?: string;
  where?: string;
  location?: string;
  companysearch?: boolean;
  company?: string;
}

const appendSpacePrefix = (str: string) => (size(str) ? ` ${str}` : '');

const getParamKey = (key: keyof TParams, isCompanySearch: boolean) => {
  if (isCompanySearch && key === 'keywords') {
    return 'company';
  } else if (key === 'where') {
    return 'location';
  }
  return key;
};

const getTitle = (
  replaceableKeyValues: ReplaceableKeyValues,
  titleType: TitleType,
  pageLocale: string,
  pagePathName: string,
): string | null => {
  const {
    brand,
    classDescription,
    country,
    keywords,
    locationDescription,
    month,
    pageNumberText,
    subclassOnlyDescription,
    workTypeDescription,
    workArrangementDescription,
    year,
  } = replaceableKeyValues;

  const auTitleTypes: TitleTypeValues = {
    company: `${keywords} Jobs in All ${country} - ${brand}`,
    'company-location': `Jobs at ${keywords} in ${locationDescription}${pageNumberText} - ${brand}`,
    'company-worktype': `Jobs at ${keywords} ${workTypeDescription}${pageNumberText} - ${brand}`,
    'company-location-worktype': `${keywords} ${workTypeDescription} Jobs in ${locationDescription}${pageNumberText} - ${brand}`,
    classification: `${classDescription} Jobs in All ${country}${pageNumberText} - ${brand}`,
    'classification-company': `${classDescription} Jobs at ${keywords}${pageNumberText} - ${brand}`,
    'classification-company-worktype': `Jobs at ${keywords} ${workTypeDescription} in ${classDescription}${pageNumberText} - ${brand}`,
    'classification-company-subclassification-worktype': `Jobs at ${keywords} ${workTypeDescription} in ${classDescription}${pageNumberText} - ${brand}`,
    'classification-keywords': `${keywords} ${classDescription} Jobs in All ${country}${pageNumberText} - ${brand}`,
    'classification-keywords-location': `${keywords} ${classDescription} Jobs in ${locationDescription}${pageNumberText} - ${brand}`,
    'classification-keywords-location-worktype': `${keywords} ${workTypeDescription} ${classDescription} Jobs in ${locationDescription}${pageNumberText} - ${brand}`,
    'classification-location': `${classDescription} Jobs in ${locationDescription}${pageNumberText} - ${brand}`,
    'classification-location-worktype': `${workTypeDescription} ${classDescription} Jobs in ${locationDescription}${pageNumberText} - ${brand}`,
    'classification-location-subclassification': `${subclassOnlyDescription} Jobs in ${locationDescription}${pageNumberText} - ${brand}`,
    'classification-location-subclassification-worktype': `${workTypeDescription} ${subclassOnlyDescription} Jobs in ${locationDescription}${pageNumberText} - ${brand}`,
    'classification-subclassification': `${subclassOnlyDescription} Jobs in ${country}${pageNumberText} - ${month} ${year} | ${brand}`,
    'classification-worktype': `${workTypeDescription} ${classDescription} Jobs in All ${country}${pageNumberText} - ${brand}`,
    keywords: `${keywords} Jobs in All ${country}${pageNumberText} - ${brand}`,
    'keywords-location': `${keywords} Jobs in ${locationDescription}${pageNumberText} - ${month} ${year} | ${brand}`,
    'keywords-location-worktype': `${keywords} ${workTypeDescription} Jobs in ${locationDescription}${pageNumberText} - ${brand}`,
    'keywords-worktype': `${keywords} ${workTypeDescription} Jobs in ${country}${pageNumberText} - ${month} ${year} | ${brand}`,
    location: `Jobs in ${locationDescription}${pageNumberText} - ${brand}`,
    'location-worktype': `${workTypeDescription} Jobs in ${locationDescription}${pageNumberText} - ${month} ${year} | ${brand}`,
    worktype: `${workTypeDescription} Jobs in All ${country}${pageNumberText} - ${brand}`,
    workarrangement: `${workArrangementDescription} Jobs in All ${country}${pageNumberText} - ${brand}`,
    'classification-workarrangement': `${workArrangementDescription} ${classDescription} Jobs in All ${country}${pageNumberText} - ${brand}`,
    'classification-subclassification-workarrangement': `${workArrangementDescription} ${subclassOnlyDescription} Jobs in All ${country}${pageNumberText} - ${month} ${year} | ${brand}`,
    'classification-company-workarrangement': `${keywords} ${workArrangementDescription} ${classDescription} Jobs in All ${country}${pageNumberText} - ${brand}`,
    'classification-company-subclassification-workarrangement': `${keywords} ${workArrangementDescription} ${subclassOnlyDescription} Jobs in All ${country}${pageNumberText} - ${brand}`,
    'classification-keywords-workarrangement': `${workArrangementDescription} ${keywords} ${classDescription} Jobs in All ${country}${pageNumberText} - ${brand}`,
    'classification-keywords-subclassification-workarrangement': `${workArrangementDescription} ${keywords} ${subclassOnlyDescription} Jobs in All ${country}${pageNumberText} - ${brand}`,
    'classification-keywords-location-workarrangement': `${workArrangementDescription} ${keywords} ${classDescription} Jobs in ${locationDescription}${pageNumberText} - ${brand}`,
    'classification-keywords-location-subclassification-workarrangement': `${workArrangementDescription} ${keywords} ${subclassOnlyDescription} Jobs in ${locationDescription}${pageNumberText} - ${brand}`,
    'classification-keywords-workarrangement-worktype': `${workArrangementDescription} ${keywords} ${workTypeDescription} ${classDescription} Jobs in All ${country}${pageNumberText} - ${brand}`,
    'classification-keywords-subclassification-workarrangement-worktype': `${workArrangementDescription} ${keywords} ${workTypeDescription} ${subclassOnlyDescription} Jobs in All ${country}${pageNumberText} - ${brand}`,
    'classification-keywords-location-workarrangement-worktype': `${workArrangementDescription} ${keywords} ${workTypeDescription} ${classDescription} Jobs in ${locationDescription}${pageNumberText} - ${brand}`,
    'classification-keywords-location-subclassification-workarrangement-worktype': `${workArrangementDescription} ${keywords} ${workTypeDescription} ${subclassOnlyDescription} Jobs in ${locationDescription}${pageNumberText} - ${brand}`,
    'classification-location-workarrangement': `${workArrangementDescription} ${classDescription} Jobs in ${locationDescription}${pageNumberText} - ${brand}`,
    'classification-location-subclassification-workarrangement': `${workArrangementDescription} ${subclassOnlyDescription} Jobs in ${locationDescription}${pageNumberText} - ${brand}`,
    'classification-company-location-workarrangement': `${keywords} ${workArrangementDescription} ${classDescription} Jobs in ${locationDescription}${pageNumberText} - ${brand}`,
    'classification-company-location-subclassification-workarrangement': `${keywords} ${workArrangementDescription} ${subclassOnlyDescription} Jobs in ${locationDescription}${pageNumberText} - ${brand}`,
    'classification-workarrangement-worktype': `${workArrangementDescription} ${workTypeDescription} ${classDescription} Jobs in All ${country}${pageNumberText} - ${brand}`,
    'classification-subclassification-workarrangement-worktype': `${workArrangementDescription} ${workTypeDescription} ${subclassOnlyDescription} Jobs in All ${country}${pageNumberText} - ${brand}`,
    'classification-company-workarrangement-worktype': `${keywords} ${workArrangementDescription} ${workTypeDescription} ${classDescription} Jobs in All ${country}${pageNumberText} - ${brand}`,
    'classification-company-subclassification-workarrangement-worktype': `${keywords} ${workArrangementDescription} ${workTypeDescription} ${subclassOnlyDescription} Jobs in All ${country}${pageNumberText} - ${brand}`,
    'classification-location-workarrangement-worktype': `${workArrangementDescription} ${workTypeDescription} ${classDescription} Jobs in ${locationDescription}${pageNumberText} - ${brand}`,
    'classification-location-subclassification-workarrangement-worktype': `${workArrangementDescription} ${workTypeDescription} ${subclassOnlyDescription} Jobs in ${locationDescription}${pageNumberText} - ${brand}`,
    'classification-company-location-workarrangement-worktype': `${keywords} ${workArrangementDescription} ${workTypeDescription} ${classDescription} Jobs in ${locationDescription}${pageNumberText} - ${brand}`,
    'classification-company-location-subclassification-workarrangement-worktype': `${keywords} ${workArrangementDescription} ${workTypeDescription} ${subclassOnlyDescription} Jobs in ${locationDescription}${pageNumberText} - ${brand}`,
    'company-workarrangement': `${keywords} ${workArrangementDescription} Jobs in All ${country}${pageNumberText} - ${brand}`,
    'keywords-workarrangement': `${workArrangementDescription} ${keywords} Jobs in All ${country}${pageNumberText} - ${brand}`,
    'keywords-location-workarrangement': `${workArrangementDescription} ${keywords} Jobs in ${locationDescription}${pageNumberText} - ${month} ${year} | ${brand}`,
    'keywords-workarrangement-worktype': `${workArrangementDescription} ${keywords} ${workTypeDescription} Jobs in All ${country}${pageNumberText} - ${month} ${year} | ${brand}`,
    'keywords-location-workarrangement-worktype': `${workArrangementDescription} ${keywords} ${workTypeDescription} Jobs in ${locationDescription}${pageNumberText} - ${brand}`,
    'location-workarrangement': `${workArrangementDescription} Jobs in ${locationDescription}${pageNumberText} - ${brand}`,
    'company-location-workarrangement': `${keywords} ${workArrangementDescription} Jobs in ${locationDescription}${pageNumberText} - ${month} ${year} | ${brand}`,
    'workarrangement-worktype': `${workArrangementDescription} ${workTypeDescription} Jobs in All ${country}${pageNumberText} - ${brand}`,
    'company-workarrangement-worktype': `${keywords} ${workArrangementDescription} ${workTypeDescription} Jobs in All ${country}${pageNumberText} - ${month} ${year} | ${brand}`,
    'location-workarrangement-worktype': `${workArrangementDescription} ${workTypeDescription} Jobs in ${locationDescription}${pageNumberText} - ${month} ${year} | ${brand}`,
    'company-location-workarrangement-worktype': `${keywords} ${workArrangementDescription} ${workTypeDescription} Jobs in ${locationDescription}${pageNumberText} - ${brand}`,
  };

  const nzTitleTypes: TitleTypeValues = {
    ...auTitleTypes,
    keywords: `${keywords} Jobs in ${country}, Job Vacancies${pageNumberText} - ${month} ${year} | ${brand}`,
    'classification-keywords': `${keywords} ${classDescription} Jobs in ${country}, Job Vacancies${pageNumberText} - ${month} ${year} | ${brand}`,
    'keywords-location': `${keywords} Jobs in ${locationDescription}, Job Vacancies${pageNumberText} - ${month} ${year} | ${brand}`,
    'keywords-worktype': `${keywords} ${workTypeDescription} Jobs in All ${country}, Job Vacancies${pageNumberText} - ${month} ${year} | ${brand}`,
    'keywords-location-worktype': `${keywords} ${workTypeDescription} Jobs in ${locationDescription}, Job Vacancies${pageNumberText} - ${month} ${year} | ${brand}`,
    classification: `${classDescription} Jobs in ${country}, Job Vacancies${pageNumberText} - ${month} ${year} | ${brand}`,
    'classification-subclassification': `${classDescription} Jobs in ${country}, Job Vacancies${pageNumberText} - ${month} ${year} | ${brand}`,
    location: `Jobs in ${locationDescription}, Job Vacancies${pageNumberText} - ${month} ${year} | ${brand}`,
    'classification-location': `${classDescription} Jobs in ${locationDescription}, Job Vacancies${pageNumberText} - ${month} ${year} | ${brand}`,
    'classification-location-subclassification': `${classDescription} Jobs in ${locationDescription}, Job Vacancies${pageNumberText} - ${month} ${year} | ${brand}`,
    'classification-location-worktype': `${workTypeDescription} ${classDescription} Jobs in ${locationDescription}, Job Vacancies${pageNumberText} - ${month} ${year} | ${brand}`,
    'classification-keywords-location': `${keywords} ${classDescription} Jobs in ${locationDescription}, Job Vacancies${pageNumberText} - ${month} ${year} | ${brand}`,
    'classification-keywords-location-worktype': `${keywords} ${workTypeDescription} ${classDescription} Jobs in ${locationDescription}, Job Vacancies${pageNumberText} - ${month} ${year} | ${brand}`,
    'classification-location-subclassification-worktype': `${workTypeDescription} ${classDescription} Jobs in ${locationDescription}, Job Vacancies${pageNumberText} - ${month} ${year} | ${brand}`,
    'location-worktype': `${workTypeDescription} Jobs in ${locationDescription}, Job Vacancies${pageNumberText} - ${month} ${year} | ${brand}`,
    worktype: `${workTypeDescription} Jobs in ${country}, Job Vacancies${pageNumberText} - ${month} ${year} | ${brand}`,
    'classification-worktype': `${workTypeDescription} ${classDescription} Jobs in All ${country}, Job Vacancies${pageNumberText} - ${month} ${year} | ${brand}`,
    company: `Jobs at ${keywords}, Job Vacancies${pageNumberText} - ${month} ${year} | ${brand}`,
    'classification-company': `${keywords} ${classDescription} Jobs, Job Vacancies${pageNumberText} - ${month} ${year} | ${brand}`,
    'company-location': `Jobs at ${keywords} in ${locationDescription}, Job Vacancies${pageNumberText} - ${month} ${year} | ${brand}`,
    'company-worktype': `Jobs at ${keywords} ${workTypeDescription}, Job Vacancies${pageNumberText} - ${month} ${year} | ${brand}`,
    'company-location-worktype': `Jobs in ${locationDescription} - Search Job Vacancies - Career${pageNumberText} - ${month} ${year} | ${brand}`,
    'classification-company-worktype': `Jobs at ${keywords} ${workTypeDescription} in ${classDescription}, Job Vacancies${pageNumberText} - ${month} ${year} | ${brand}`,
    'classification-company-subclassification-worktype': `Jobs at ${keywords} ${workTypeDescription} in ${classDescription}, Job Vacancies${pageNumberText} - ${month} ${year} | ${brand}`,
    workarrangement: `${workArrangementDescription} Jobs in All ${country}, Job Vacancies${pageNumberText} - ${month} ${year} | ${brand}`,
    'classification-workarrangement': `${workArrangementDescription} ${classDescription} Jobs in All ${country}, Job Vacancies${pageNumberText} - ${month} ${year} | ${brand}`,
    'classification-subclassification-workarrangement': `${workArrangementDescription} ${subclassOnlyDescription} Jobs in All ${country}, Job Vacancies${pageNumberText} - ${month} ${year} | ${brand}`,
    'classification-company-workarrangement': `${keywords} ${workArrangementDescription} ${classDescription} Jobs in All ${country}, Job Vacancies${pageNumberText} - ${month} ${year} | ${brand}`,
    'classification-company-subclassification-workarrangement': `${keywords} ${workArrangementDescription} ${subclassOnlyDescription} Jobs in All ${country}, Job Vacancies${pageNumberText} - ${month} ${year} | ${brand}`,
    'classification-keywords-workarrangement': `${workArrangementDescription} ${keywords} ${classDescription} Jobs in All ${country}, Job Vacancies${pageNumberText} - ${month} ${year} | ${brand}`,
    'classification-keywords-subclassification-workarrangement': `${workArrangementDescription} ${keywords} ${subclassOnlyDescription} Jobs in All ${country}, Job Vacancies${pageNumberText} - ${month} ${year} | ${brand}`,
    'classification-keywords-location-workarrangement': `${workArrangementDescription} ${keywords} ${classDescription} Jobs in ${locationDescription}, Job Vacancies${pageNumberText} - ${month} ${year} | ${brand}`,
    'classification-keywords-location-subclassification-workarrangement': `${workArrangementDescription} ${keywords} ${subclassOnlyDescription} Jobs in ${locationDescription}, Job Vacancies${pageNumberText} - ${month} ${year} | ${brand}`,
    'classification-keywords-workarrangement-worktype': `${workArrangementDescription} ${keywords} ${workTypeDescription} ${classDescription} Jobs in All ${country}, Job Vacancies${pageNumberText} - ${month} ${year} | ${brand}`,
    'classification-keywords-subclassification-workarrangement-worktype': `${workArrangementDescription} ${keywords} ${workTypeDescription} ${subclassOnlyDescription} Jobs in All ${country}, Job Vacancies${pageNumberText} - ${month} ${year} | ${brand}`,
    'classification-keywords-location-workarrangement-worktype': `${workArrangementDescription} ${keywords} ${workTypeDescription} ${classDescription} Jobs in ${locationDescription}, Job Vacancies${pageNumberText} - ${month} ${year} | ${brand}`,
    'classification-keywords-location-subclassification-workarrangement-worktype': `${workArrangementDescription} ${keywords} ${workTypeDescription} ${subclassOnlyDescription} Jobs in ${locationDescription}, Job Vacancies${pageNumberText} - ${month} ${year} | ${brand}`,
    'classification-location-workarrangement': `${workArrangementDescription} ${classDescription} Jobs in ${locationDescription}, Job Vacancies${pageNumberText} - ${month} ${year} | ${brand}`,
    'classification-location-subclassification-workarrangement': `${workArrangementDescription} ${subclassOnlyDescription} Jobs in ${locationDescription}, Job Vacancies${pageNumberText} - ${month} ${year} | ${brand}`,
    'classification-company-location-workarrangement': `${keywords} ${workArrangementDescription} ${classDescription} Jobs in ${locationDescription}, Job Vacancies${pageNumberText} - ${month} ${year} | ${brand}`,
    'classification-company-location-subclassification-workarrangement': `${keywords} ${workArrangementDescription} ${subclassOnlyDescription} Jobs in ${locationDescription}, Job Vacancies${pageNumberText} - ${month} ${year} | ${brand}`,
    'classification-workarrangement-worktype': `${workArrangementDescription} ${workTypeDescription} ${classDescription} Jobs in All ${country}, Job Vacancies${pageNumberText} - ${month} ${year} | ${brand}`,
    'classification-subclassification-workarrangement-worktype': `${workArrangementDescription} ${workTypeDescription} ${subclassOnlyDescription} Jobs in All ${country}, Job Vacancies${pageNumberText} - ${month} ${year} | ${brand}`,
    'classification-company-workarrangement-worktype': `${keywords} ${workArrangementDescription} ${workTypeDescription} ${classDescription} Jobs in All ${country}, Job Vacancies${pageNumberText} - ${month} ${year} | ${brand}`,
    'classification-company-subclassification-workarrangement-worktype': `${keywords} ${workArrangementDescription} ${workTypeDescription} ${subclassOnlyDescription} Jobs in All ${country}, Job Vacancies${pageNumberText} - ${month} ${year} | ${brand}`,
    'classification-location-workarrangement-worktype': `${workArrangementDescription} ${workTypeDescription} ${classDescription} Jobs in ${locationDescription}, Job Vacancies${pageNumberText} - ${month} ${year} | ${brand}`,
    'classification-location-subclassification-workarrangement-worktype': `${workArrangementDescription} ${workTypeDescription} ${subclassOnlyDescription} Jobs in ${locationDescription}, Job Vacancies${pageNumberText} - ${month} ${year} | ${brand}`,
    'classification-company-location-workarrangement-worktype': `${keywords} ${workArrangementDescription} ${workTypeDescription} ${classDescription} Jobs in ${locationDescription}, Job Vacancies${pageNumberText} - ${month} ${year} | ${brand}`,
    'classification-company-location-subclassification-workarrangement-worktype': `${keywords} ${workArrangementDescription} ${workTypeDescription} ${subclassOnlyDescription} Jobs in ${locationDescription}, Job Vacancies${pageNumberText} - ${month} ${year} | ${brand}`,
    'company-workarrangement': `${keywords} ${workArrangementDescription} Jobs in All ${country}, Job Vacancies${pageNumberText} - ${month} ${year} | ${brand}`,
    'keywords-workarrangement': `${workArrangementDescription} ${keywords} Jobs in All ${country}, Job Vacancies${pageNumberText} - ${month} ${year} | ${brand}`,
    'keywords-location-workarrangement': `${workArrangementDescription} ${keywords} Jobs in ${locationDescription}, Job Vacancies${pageNumberText} - ${month} ${year} | ${brand}`,
    'keywords-workarrangement-worktype': `${workArrangementDescription} ${keywords} ${workTypeDescription} Jobs in All ${country}, Job Vacancies${pageNumberText} - ${month} ${year} | ${brand}`,
    'keywords-location-workarrangement-worktype': `${workArrangementDescription} ${keywords} ${workTypeDescription} Jobs in ${locationDescription}, Job Vacancies${pageNumberText} - ${month} ${year} | ${brand}`,
    'location-workarrangement': `${workArrangementDescription} Jobs in ${locationDescription}, Job Vacancies${pageNumberText} - ${month} ${year} | ${brand}`,
    'company-location-workarrangement': `${keywords} ${workArrangementDescription} Jobs in ${locationDescription}, Job Vacancies${pageNumberText} - ${month} ${year} | ${brand}`,
    'workarrangement-worktype': `${workArrangementDescription} ${workTypeDescription} Jobs in All ${country}, Job Vacancies${pageNumberText} - ${month} ${year} | ${brand}`,
    'company-workarrangement-worktype': `${keywords} ${workArrangementDescription} ${workTypeDescription} Jobs in All ${country}, Job Vacancies${pageNumberText} - ${month} ${year} | ${brand}`,
    'location-workarrangement-worktype': `${workArrangementDescription} ${workTypeDescription} Jobs in ${locationDescription}, Job Vacancies${pageNumberText} - ${month} ${year} | ${brand}`,
    'company-location-workarrangement-worktype': `${keywords} ${workArrangementDescription} ${workTypeDescription} Jobs in ${locationDescription}, Job Vacancies${pageNumberText} - ${month} ${year} | ${brand}`,
  };

  const titleTypes = country === 'New Zealand' ? nzTitleTypes : auTitleTypes;
  return (
    titleOverride({
      replaceableKeyValues,
      titleType,
      pageLocale,
      pagePathName,
    }) || titleTypes[titleType as TitleType]
  );
};

const generateTitleTypeKey = (
  searchParams: SearchParams,
  isCompanySearch: boolean,
): TitleType => {
  const cleanParams = filterObject(searchParams);
  const sortedKeys = Object.keys(cleanParams)
    .map((key) => getParamKey(key as keyof TParams, isCompanySearch))
    .sort()
    .filter((item, index, array) => array.indexOf(item) === index)
    .filter((key) => key !== 'companysearch');
  return `${sortedKeys.join('-')}` as TitleType;
};

const generateTitle = ({
  searchParams,
  location,
  page,
  fallBackTitle,
  country,
  isCompanySearch,
  pageLocale,
  pagePathName,
  jobCount,
  shortLocationName,
  locale,
}: {
  searchParams: SearchParams | null | undefined;
  location: SearchLocation | SearchResultLocationV5 | null | undefined;
  page: string;
  fallBackTitle: string;
  country: Country;
  isCompanySearch: boolean;
  pageLocale: string;
  pagePathName: string;
  jobCount: string;
  shortLocationName?: string;
  locale: Locale;
}) => {
  const titlePageNumber: string =
    parseInt(page, 10) > 1 ? `(Page ${page})` : '';

  const formattedFallBackTitle = size(fallBackTitle)
    ? `${fallBackTitle}${appendSpacePrefix(titlePageNumber)} - SEEK`
    : 'SEEK';

  if (!searchParams) {
    // searchParams is allowed to be null/undefind but we then start doing object operations on it so a good reason to eject early
    return formattedFallBackTitle;
  }

  const key = generateTitleTypeKey(searchParams, isCompanySearch);
  const countryText: CountryTextType = isAU(country)
    ? 'Australia'
    : 'New Zealand';

  const keywords: string | null | undefined = toProperCase(
    get(searchParams, 'keywords'),
  );
  const classification: string | null | undefined = get(
    searchParams,
    'classification',
  );
  const subclassification: string | null | undefined = get(
    searchParams,
    'subclassification',
  );
  const workType: string | null | undefined = get(searchParams, 'worktype');
  const locationDescription: string =
    get(location, 'description') || countryText; // Fall back to country if location is not available
  const workTypeDescription: string = toProperCase(formatWorkType(workType));

  const workArrangementList = getWorkArrangements(locale);
  const workArrangement: string | null | undefined =
    searchParams?.workarrangement;

  const workArrangementDescription = startCase(
    workArrangementList?.find(({ id }) => id === workArrangement)?.label,
  );

  const classDescription: string = getClassDescription(
    classification,
    subclassification,
  );
  const subclassOnlyDescription: string = getClassDescription(
    undefined,
    subclassification,
  );

  // If classification is passed but it's invalid
  if (classification && !classDescription) {
    return formattedFallBackTitle;
  }

  const formattedDate = getMonthAndYear();
  const [month, year] = formattedDate.split(' ');

  const generatedTitle = getTitle(
    {
      brand: 'SEEK',
      classDescription,
      country: countryText,
      fallBackTitle,
      keywords,
      locationDescription,
      month,
      pageNumberText: appendSpacePrefix(titlePageNumber),
      subclassOnlyDescription,
      workTypeDescription,
      workArrangementDescription,
      year,
      jobCount,
      shortLocationName: shortLocationName || locationDescription, // Fallback to locationDescription if shortLocationName is not available
    },
    key,
    pageLocale,
    pagePathName,
  );

  if (generatedTitle) {
    return generatedTitle;
  }

  return formattedFallBackTitle;
};

export default generateTitle;
